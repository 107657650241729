import "bootstrap/js/dist/modal";

import { FormUtilities } from "../FormUtilities";
import { AjaxRequest } from "../AjaxUtilities";
import { Dialog } from "../DialogUtilities";
import { IAjaxError } from "../../dogfish.server.interfaces";
import { EventHandler } from "../EventUtilities";
import { ModalStacker } from "./ModalStacker";
import { Logger } from "../Logger";

export abstract class ModalBase {
	protected readonly enableLogging: boolean;
	protected $modal: JQuery<HTMLElement>;

	private showEventHandler = new EventHandler();
	private hideEventHandler = new EventHandler();

	protected constructor($modal: JQuery<HTMLElement>, enableLogging = false) {
		this.$modal = $modal;
		this.enableLogging = enableLogging;
		this.addBodyListenerEvents();
	}

	protected abstract postReload(): void;

	modalElementExists(): boolean {
		return this.$modal.length > 0;
	}

	show(url?: string): Promise<void> {
		if (url !== undefined) {
			var getReloadHtml = this.getModalHtml(url);
			var show = this.show()
				.then(() => AjaxRequest.GetResolvedPromise(100)) // wait a bit for less jank
				.then(() => this.toggleLoading(true));

			return $.when(show, getReloadHtml).then((_, html) => {
				var $newModal = $(html);
				this.$modal.html($newModal.html());
				FormUtilities.resetFormValidation(this.$modal);

				this.toggleLoading(false);
				this.postReload();
			}).catch(err => {
				console.log("ERR", err);
			});
		}

		var dfd = $.Deferred();
		this.$modal.one('shown.bs.modal', () => dfd.resolve());
		this.$modal.modal('show');

		this.log('Showing modal');
		this.showEventHandler.run({});

		var $form = this.$modal.find('form');
		if ($form.length)
			FormUtilities.clearFormValidation($form);

		return dfd.promise();
	}

	hide(): Promise<void> {
		var dfd = $.Deferred();
		this.$modal.one('hidden.bs.modal', () => dfd.resolve());

		this.$modal.modal('hide');
		this.log('Hiding modal');
		this.hideEventHandler.run({});

		return dfd.promise();
	}

	remove() {
		this.$modal.modal('dispose');
		this.log('Disposing modal');
	}

	// If you are overriding this - Please use show instead!
	protected reload(url: string): Promise<void> {
		return this.getModalHtml(url).then(html => {
			var $newModal = $(html);
			this.$modal.html($newModal.html());
			FormUtilities.resetFormValidation(this.$modal);
		});
	}

	private getModalHtml(url: string): Promise<string> {
		Logger.debug("Reloading modal", url);
		return AjaxRequest.getRequest<string>(url)
			.catch((err: IAjaxError) => {
				if (!err.isHandled)
					return Dialog.failed(err.message).then(() => {
						this.hide();
						throw err;
					});
				else {
					this.hide();
				}
				throw err;
			});
	}

	protected toggleLoading(toggle: boolean): void {
		this.$modal.toggleClass('loading', toggle);
	}

	protected log(message: string, data?: any) {
		if (this.enableLogging)
			data == undefined ? Logger.debug(message) : Logger.debug(message, data);
	}

	onShow(handler: { (): {} }): void {
		this.showEventHandler.add(handler);
	}
	onHide(handler: { (): {} }): void {
		this.hideEventHandler.add(handler);
	}

	private addBodyListenerEvents() {
		//$('body').on('show.bs.modal', '.modal', function () {
		//});
		$('body').on('shown.bs.modal', '.modal', function () {
			ModalStacker.addModal($(this));
		});
		$('body').on('hide.bs.modal', '.modal', function () {
			ModalStacker.removeModal($(this));
		});
		$('body').on('hidden.bs.modal', '.modal', function () {
			ModalStacker.modalHidden();
		});
	}
}