import "pwstrength-bootstrap/dist/pwstrength-bootstrap.min.js";

export class PasswordStrength {
	static initPasswordStength($element: JQuery) {
		(<any>$element).pwstrength({
			ui: {
				showVerdictsInsideProgressBar: true,
				progressExtraCssClasses: "password-strength-wrapper",
				progressBarExtraCssClasses: "password-strength-bar",
				progressBarMinPercentage: 19
				}
		});
	}
}