import { Logger } from "./Logger";

export class DogfishNotification {
    static windowMessage(msg: string) {
        this.notify(msg);
    }

    private static notify(msg: string) {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
            Logger.debug("Browser does not support notifications");
        }

        // Let's check whether notification permissions have already been granted
        else if ((Notification as any).permission === "granted") {
            // If it's okay let's create a notification
            Logger.debug("Dogfish notification permission granted", (Notification as any).permission);
            var notification = new Notification(msg);
        }

        // Otherwise, we need to ask the user for permission
        else if ((Notification as any).permission !== "denied") {
            Logger.debug("Dogfish notification permission denied, attempting to request permission");
            Notification.requestPermission(permission => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    Logger.debug("Dogfish notification permission granted", (Notification as any).permission);
                    var notification = new Notification(msg);
                } else {
                    Logger.debug("Dogfish notification permission request unsuccessful", (Notification as any).permission);
                }
            });
        } else {
            Logger.debug("Dogfish notification unknown result", (Notification as any).permission);
        }
    }
}