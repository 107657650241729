import { forEach } from "jszip";
import Swal, { SweetAlertType } from "sweetalert2";
import { SweetAlertOptions } from "sweetalert2";
import { IActivityLogTemplateDetailVM, IDeleteLocationsInformationVM, ILocationVM } from "../ViewModels";
import { AjaxRequest } from "./AjaxUtilities";
import { DataTableHelper } from "./DataTableHelper";
import { Select2Util } from "./SelectUtilities";

export interface IDialogConfirmSettings {
	type?: SweetAlertType;
	title?: string;
	message?: string;
	confirmText?: string;
	cancelText?: string;
	onResult?: () => Promise<IDialogSuccess>;
}
export interface IDialogFileUploadSettings {
	type?: SweetAlertType;
	title?: string;
	message?: string;
	confirmText?: string;
	cancelText?: string;
	allowedMimeTypes?: string[];
	//onResult?: () => Promise<IDialogSuccess>;
}

export class Dialog {
	// Used to signal to the confirm modal process, when an overrode alert is closed
	// If not used, the overrode alert will close instantly as part of the confirm alert's process
	private static overrodeAlertDfd?: JQuery.Deferred<any, any, any>;
	private static dismissReasons = new Array<string>(Swal.DismissReason[Swal.DismissReason.backdrop], Swal.DismissReason[Swal.DismissReason.close], Swal.DismissReason[Swal.DismissReason.esc]);

	public static fileUpload(settings: IDialogFileUploadSettings): Promise<File | undefined> {
		var inputSettings: any = {};
		if (settings.allowedMimeTypes != undefined && settings.allowedMimeTypes.length > 0)
			inputSettings.accept = settings.allowedMimeTypes.join(',');

		return Swal.queue([{
			title: settings.title || 'Upload file',
			text: settings.message || 'Select the file you wish to upload',
			type: settings.type || 'question',
			showCancelButton: true,
			cancelButtonText: settings.cancelText || 'Cancel',
			confirmButtonText: settings.confirmText || 'Upload',
			buttonsStyling: false,
			confirmButtonClass: 'btn btn-primary m-r-10',
			cancelButtonClass: 'btn btn-default',
			customClass: 'smallWarningModal',
			showLoaderOnConfirm: true,
			allowOutsideClick: false,
			showCloseButton: true,
			input: 'file',
			inputAttributes: inputSettings,
			onBeforeOpen: () => {
				$(".swal2-file").change(function () {
					var reader = new FileReader();
					reader.readAsDataURL((this as any).files[0]);
				});
			}
		}]).then((result: any) => {
			if (result.dismiss || result.value == undefined || result.value.length <= 0)
				return undefined;

			var file = result.value[0] as File;
			return file;
		});
	}

	public static confirm(settings: IDialogConfirmSettings): Promise<boolean | undefined> {
		return Swal.queue([{
			title: settings.title || 'Are you sure?',
			text: settings.message || "You won't be able to revert this!",
			type: settings.type || 'warning',
			showCancelButton: true,
			cancelButtonText: settings.cancelText || 'Cancel',
			confirmButtonText: settings.confirmText || 'Confirm',
			buttonsStyling: false,
			confirmButtonClass: 'btn btn-primary m-r-10',
			cancelButtonClass: 'btn btn-default',
			customClass: 'smallWarningModal',
			showLoaderOnConfirm: true,
			allowOutsideClick: false,
			showCloseButton: true,
			preConfirm: () => {
				if (settings.onResult === undefined) {
					return $.Deferred().resolve().promise();
				}
				return settings.onResult().then(result => {
					if (result.ignore)
						return <any>(Dialog.overrodeAlertDfd ? Dialog.overrodeAlertDfd.promise() : $.Deferred().resolve().promise());

					if (result.success)
						return Swal.insertQueueStep(<Object>{
							toast: true,
							position: 'top',
							type: 'success',
							title: result.message || 'Your work has been saved',
							showConfirmButton: false,
							timer: 3000,
							useRejections: false
						});

					return Swal.insertQueueStep(<Object>{
						type: 'error',
						title: result.message || 'Failed to complete action',
						buttonsStyling: false,
						confirmButtonClass: 'btn btn-primary',
						customClass: 'smallWarningModal',
					});
				}).then(() => { });
			}
		}]).then((result: any) => {
			if (result.dismiss && result.dismiss == Swal.DismissReason.cancel)
				return false;

			if ((result.dismiss && (<any>this.dismissReasons).includes(result.dismiss)) || result.value == undefined || result.value.length <= 0)
				return undefined;

			return true;
		});
	}

	public static confirmAssetStatus(html: string, onResult?: (value: any) => Promise<IDialogSuccess>): Promise<boolean> {
		return Swal.queue([{
			title: 'Confirm Asset status',
			type: 'question',
			html: html,
			showCancelButton: true,
			confirmButtonText: 'Confirm',
			buttonsStyling: false,
			confirmButtonClass: 'btn btn-primary m-r-10',
			cancelButtonClass: 'btn btn-default',
			customClass: 'smallWarningModal',
			showLoaderOnConfirm: true,
			allowOutsideClick: false,
			showCloseButton: true,
			preConfirm: () => {
				if (onResult === undefined) {
					return $.Deferred().resolve().promise();
				}
				var value = <number>$(Swal.getContent()).find('input[type="radio"]:checked').val();
				return onResult(value).then(Dialog.handleDialogResult).then(() => { });
			}
		}]).then((result: any) => {
			if (result.dismiss)
				return false;

			return true;
		});
	}

	private static handleDialogResult(result: IDialogSuccess) {
		if (result.ignore)
			return <any>(Dialog.overrodeAlertDfd ? Dialog.overrodeAlertDfd.promise() : $.Deferred().resolve().promise());

		if (result.success)
			return Swal.insertQueueStep(<Object>{
				toast: true,
				position: 'top',
				type: 'success',
				title: result.message || 'Your work has been saved',
				showConfirmButton: false,
				timer: 3000,
				useRejections: false
			});

		return Swal.insertQueueStep(<Object>{
			type: 'error',
			title: result.message || 'Failed to complete action',
			buttonsStyling: false,
			confirmButtonClass: 'btn btn-primary',
			customClass: 'smallWarningModal',
		});
	}

	public static success(title?: string) {
		Swal({
			toast: true,
			position: 'top',
			type: 'success',
			title: title || 'Your work has been saved',
			showConfirmButton: false,
			timer: 3000,
			useRejections: false
		});
	}
	public static failed(message?: string, title?: string, customClass?: string): Promise<void> {
		var settings = this.getErrorSettings({
			titleText: title || 'Failed',
			html: message || 'There was a problem processing your request',
			customClass: customClass || ''
		});

		return this.overrideCurrentDialog(settings);
	}
	public static successWithMessage(message?: string, title?: string): Promise<void> {
		var settings = this.getSucessSettings({
			titleText: title || 'Success',
			html: message || 'Successful'
		})

		return this.overrideCurrentDialog(settings);
	}
	public static warning(message?: string, title?: string, customClass?: string) {
		Swal(this.getWarningSettings({
			titleText: title || 'Warning',
			html: message || 'There was a problem processing your request',
			customClass: customClass || 'smallWarningModal'
		}));
	}

	public static systemFailed(message?: string, title?: string): Promise<void> {
		var settings = this.getErrorSettings({
			titleText: title || 'Oops',
			html: message || 'Something went wrong processing your request, please contact support if this continues.'
		});

		return this.overrideCurrentDialog(settings);
	}

	public static systemWarning(message?: string, title?: string): Promise<void> {
		var settings = this.getWarningSettings({
			titleText: title || 'Warning',
			html: message || 'We are unable to process you request at this time, please contact support if this continues.'
		});

		return this.overrideCurrentDialog(settings);
	}

	// TODO - Refactor this to be a generic input modal
	public static locationDeleteModal(deleteLocationsInformationVM: IDeleteLocationsInformationVM) : Promise<number | undefined> {
		var options: { [key: number]: string } = { 0 : 'No, do not reassign'};
		deleteLocationsInformationVM.otherLocations.forEach(l => {
			options[l.id] = l.name;
		})

		var activityLogText = deleteLocationsInformationVM.activityLogCount == 1
			? `There is 1 Activity Log`
			: `There are ${deleteLocationsInformationVM.activityLogCount} Activity Logs`;
		var thisTheseLocationsText = deleteLocationsInformationVM.noOfLocationsToDelete == 1 ? `this location` : `these ${deleteLocationsInformationVM.noOfLocationsToDelete} locations`;
		var itThemText = deleteLocationsInformationVM.activityLogCount == 1 ? 'it' : 'them';

		return Swal({
			title: 'Warning',
			type: 'warning',
			text: `${activityLogText} assigned to ${thisTheseLocationsText}. Would you like to reassign ${itThemText} to a new Location?`,
			target: '#locationsLocations',
			input: 'select',
			inputOptions: options,
			showCancelButton: true,
			onOpen: function () {
				Select2Util.applySelect2($(".swal2-select"), $("div.swal2-container"));
			}
		}).then((result) => {
			if (result.dismiss || result.value == undefined || result.value.length <= 0)
				return undefined;
			return result.value;
		})
	}

	public static unauthorized(): Promise<void> {
		return this.systemFailed('Your login period has expired. Please login to continue', 'Login Expired');
	}

	public static forbidden(): Promise<void> {
		return this.systemFailed('You do not have permission to access this resource', 'Forbidden');
	}

	public static appOffline(): Promise<void> {
		return this.systemWarning('We are unable to process your request while the system is being upgraded. Please try again in a couple of seconds. Please contact support if this continues', 'Upgrade in progress');
	}

	public static pageNotFound(): Promise<void> {
		return this.systemFailed('Sorry we could not find the page you were looking for', '404 Page not found');
	}

	public static requestTooLarge(): Promise<void> {
		return this.systemFailed('Request size exceeds that allowed by the server. If you are uploading a file, please make sure meets the upload size requirements', 'Request is too large');
	}

	public static unknownException(): Promise<void> {
		return this.systemFailed('Something went wrong processing your request, please contact support if this continues.', 'Oops');
	}

	public static networkError(): Promise<void> {
		return this.systemFailed('Unable to connect to the server', 'Connection failed');
	}

	private static getErrorSettings(settings: any): SweetAlertOptions & { useRejections?: false | undefined } {
		var errorDefault = {
			position: 'top',
			type: 'error',
			showConfirmButton: true,
			useRejections: false
		};

		var options = $.extend({}, errorDefault, settings);

		return <SweetAlertOptions & { useRejections?: false | undefined }>options;
	}
	private static getWarningSettings(settings: any): SweetAlertOptions & { useRejections?: false | undefined } {
		var errorDefault = {
			position: 'top',
			type: 'warning',
			showConfirmButton: true,
			useRejections: false
		};

		var options = $.extend({}, errorDefault, settings);

		return <SweetAlertOptions & { useRejections?: false | undefined }>options;
	}
	private static getSucessSettings(settings: any): SweetAlertOptions & { useRejections?: false | undefined } {
		var errorDefault = {
			position: 'top',
			type: 'success',
			showConfirmButton: true,
			useRejections: false
		};

		var options = $.extend({}, errorDefault, settings);

		return <SweetAlertOptions & { useRejections?: false | undefined }>options;
	}
	public static defaultSuccessSettings(message?: string): IDialogSuccess {
		return <IDialogSuccess>{ success: true, message: message };
	}

	public static defaultFailedSettings(message?: string): IDialogSuccess {
		return <IDialogSuccess>{ success: false, message: message };
	}

	public static defaultIgnoreSettings(): IDialogSuccess {
		return <IDialogSuccess>{ ignore: true, success: false, message: '' };
	}

	private static overrideCurrentDialog(settings: any): Promise<void> {
		if (Swal.getQueueStep()) {
			Swal.close();
			settings.position = 'center';
			settings.onClose = () => {
				if (!Dialog.overrodeAlertDfd) return;
				Dialog.overrodeAlertDfd.resolve();

			}
		}

		Dialog.overrodeAlertDfd = $.Deferred();
		return Swal(settings).then(() => { });
	}
}


export interface IDialogSuccess {
	ignore: boolean;
	success: boolean;
	message: string;
} 