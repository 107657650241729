import Raphael from "raphael";
import "../../node_modules/morris.js/morris.min.js";
//import "bootstrap";
//import "../../../node_modules/bootstrap/js/dist/tooltip.js";
//import "popper.js";

export class MorrisUtilities {
    // TODO - Make more generic
    static createStackedChart($container: JQuery, data: any, xKey: string, yKeys: string[], labels: string[]): morris.GridChart {

        var options: any = {
            element: $container,
            data: data,
            xkey: xKey,
            ykeys: yKeys,
            labels: labels,
            hideHover: 'auto',
            resize: true, //defaulted to true
            stacked: true,
            xLabelAngle: 80,
            padding: 10,
            barColors: ['#0b62a4','#9baab5'],
            hoverCallback: function (index: number, options: any, content: any, row: any) {
                var $content = $('<div>' + content + '</div>');
                var $label = $content.children('div.morris-hover-row-label');
                $label.text(row.fullLabel);
                return $content.html();
            }
        }

        return Morris.Bar(options);
    }
    static createDonutChart($container: JQuery, data: any, colors?: string[], showPercentage: boolean = false): morris.DonutChart {
        var total = data.reduce((total: number, item: any) => total += item.value, 0);
        const options = {
            element: $container,
            data: data,
            colors: colors == undefined ? ["#32CD32", "#afd8f8", "#cb4b4b", "#4da74d", "#9440ed"] : colors,
            resize: true,
            formatter: function (y: any, data: any) { return showPercentage ? ((y/total) * 100).toFixed(0) + '%' : y }
        };

        var donut = Morris.Donut(options);
        donut.select(0);
        return donut;
    }
}

export interface IStackedChartDataVM {
    name: string;
    values: IStackedChartValueVM[];
}
export interface IStackedChartValueVM {
    label: string;
    shortLabel: string;
    counts: number[];
}