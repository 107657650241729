import { BooleanUtilities } from "./Utilities";

export class Logger {
    private static infoUrl = "/Error/LogInfo";
    private static debugUrl = "/Error/LogDebug";
    private static warningUrl = "/Error/LogWarning";
    private static errorUrl = "/Error/LogError";

    private static canLogInfo: boolean;
    private static canLogDebug: boolean;
    private static canLogWarning: boolean;
    private static canLogError: boolean;
    private static isInit: boolean;

    private static init() {
        if (this.isInit)
            return;

        var $logLevels = $('div.logLevels');

        this.canLogInfo = BooleanUtilities.getBool($('#CanLogInfo', $logLevels).val() as string | undefined);
        this.canLogDebug = BooleanUtilities.getBool($('#CanLogDebug', $logLevels).val() as string | undefined);
        this.canLogWarning = BooleanUtilities.getBool($('#CanLogWarning', $logLevels).val() as string | undefined);
        this.canLogError = BooleanUtilities.getBool($('#CanLogError', $logLevels).val() as string | undefined);
        this.isInit = true;
    }

    static info(message: string, ...optionalParams: any[]) {
        this.init();
        console.info(message, optionalParams);
        this.sendLog(this.canLogInfo, this.infoUrl, message);
    }
    static infoLocal(message: string, ...optionalParams: any[]) {
        this.init();
        console.info(message, optionalParams);
    }
    static debug(message: string, ...optionalParams: any[]) {
        this.init();
        console.debug(message, optionalParams);
        this.sendLog(this.canLogDebug, this.debugUrl, message);
    }
    static debugLocal(message: string, ...optionalParams: any[]) {
        this.init();
        console.debug(message, optionalParams);
    }
    static warning(message: string, ...optionalParams: any[]) {
        this.init();
        console.warn(message, optionalParams);
        this.sendLog(this.canLogWarning, this.warningUrl, message);
    }
    static error(message: string, ...optionalParams: any[]) {
        this.init();
        console.error(message, optionalParams);
        this.sendLog(this.canLogError, this.errorUrl, message);
    }

    private static sendLog(isEnabled: boolean, url: string, message: string) {
        if (!isEnabled)
            return;

        this.postRequestPromise(url, { message: message })
            .then(_value => {
                //console.debug('Log message sent to the server');
            })
            .catch(_reason => {
                console.debug('Log message failed to send to the server');
            });
    }
    private static postRequestPromise<T>(url: string, dataToPost?: any): Promise<T> {
        var dfd = $.Deferred();
        var promise = dfd.promise();

        $.ajax({
            url: url,
            method: "POST",
            data: dataToPost,
            success() {
                dfd.resolve();
            },
            error() {
                dfd.reject();
            }
        });

        return promise;
    }
}