import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
import "bootstrap/js/dist/tab";
import "bootstrap-datepicker";

import { ISiteDetailVM } from "../../ViewModels";
import { AjaxRequest } from "../../Utilities/AjaxUtilities";
import { KeyedCollection } from "../../Utilities/Utilities";
import { FormModal } from "../../Utilities/Modal/FormModal";

export class ChangeSiteDetailModal extends FormModal {
    private readonly saveCallback?: Function;
    private loadUrl: string;

    private sitesDictionary = new KeyedCollection<ISiteDetailVM>();

    constructor($modal: JQuery<HTMLElement>, onSaveCallback?: Function, enableLogging = false) {
        super($modal, enableLogging);
        this.saveCallback = onSaveCallback;
        this.loadUrl = ($modal.find('#ChangeSiteDetailModalRefreshUrl').val()) as string;

        this.applyEvents();
    }

    postReload(): void {
        super.postReload();
        this.applyEvents();
    }

    onSuccess(data: any): void {
        if (this.saveCallback != undefined)
            this.saveCallback();
        super.onSuccess(data);
    }

    populate(siteId?: string) {
        var reloadDataUrl = this.loadUrl;
        if (siteId != undefined)
            reloadDataUrl = reloadDataUrl + '/' + (siteId || '');

        return this.show(reloadDataUrl);
    }

    applyEvents() {
        var $modal = this.$modal;

        var $autocomplete = $('input.siteNameAutocomplete');
        $autocomplete.autocomplete({
            minLength: 0,
            appendTo: '#' + ($modal.attr('id') as string),
            source: (request: any, response: any) => {
                var term = request.term;
                if (this.sitesDictionary.containsKey(term)) {
                    response(this.sitesDictionary.get(term));
                    return;
                }

                AjaxRequest.getRequest<any>($autocomplete.data('autocompleteurl') + '?searchTerm=' + term)
                    .then(data => {
                        if (data.length)
                            this.sitesDictionary.add(term, data);
                        response(data);
                    });
            },
            select: (_event, ui) => {
                if (ui != undefined) {
                    const siteDetails = ui.item as ISiteDetailVM;
                    this.setSiteDetails(true, siteDetails);
                    return false;
                }

                this.setSiteDetails(false, undefined);
                return false;
            }
        });
    }

    private setSiteDetails(isDisabled: boolean, details?: ISiteDetailVM): void {
        const boolStr = isDisabled.toString();
        if (details !== undefined) {
            $('#changeSiteName', this.$form).val(details.siteName);
            $('#changeSiteId', this.$form).val(details.siteId);
            $('#AddressVM_AddressLine1', this.$form).val(details.addressVM.addressLine1);
            $('#AddressVM_AddressLine2', this.$form).val(details.addressVM.addressLine2);
            $('#AddressVM_AddressLine3', this.$form).val(details.addressVM.addressLine3);
            $('#AddressVM_City', this.$form).val(details.addressVM.city);
            $('#AddressVM_Postcode', this.$form).val(details.addressVM.postcode);
        }
        $('#changeSiteName', this.$form).attr('readonly', boolStr);
        $('#changeSiteId', this.$form).attr('readonly', boolStr);
        $('#AddressVM_AddressLine1', this.$form).attr('disabled', boolStr);
        $('#AddressVM_AddressLine2', this.$form).attr('disabled', boolStr);
        $('#AddressVM_AddressLine3', this.$form).attr('disabled', boolStr);
        $('#AddressVM_City', this.$form).attr('disabled', boolStr);
        $('#AddressVM_Postcode', this.$form).attr('disabled', boolStr);
    }

    getSiteDetails(): ISiteDetailVM {
        var data: ISiteDetailVM = {
            siteId: $('#changeSiteId', this.$form).val() as number,
            siteName: $('#changeSiteName', this.$form).val() as string,
            addressVM: {
                addressLine1: $('#AddressVM_AddressLine1', this.$form).val() as string,
                addressLine2: $('#AddressVM_AddressLine2', this.$form).val() as string,
                addressLine3: $('#AddressVM_AddressLine3', this.$form).val() as string,
                city: $('#AddressVM_City', this.$form).val() as string,
                postcode: $('#AddressVM_Postcode', this.$form).val() as string
            }
        };
        return data;
    }
}