import { DateUtilities } from "../../Utilities/Utilities";
import { Logger } from "../../Utilities/Logger";
import { Datepicker } from "../../Utilities/DatepickerUtilities";
import * as moment from "moment";

export class BreakdownReportSearch {
    static breakdownReportSubmitEvent = 'breakdownReportSubmit';
    private $container: JQuery;
    private isClientAdmin: boolean;
    isCustomSearch: boolean;

    private chart?: morris.GridChart;

    private static fromDatePickerSelector = '#From_Picker';
    private static toDatePickerSelector = '#To_Picker';
    private static comparisonFromDatePickerSelector = '#ComparisonFrom_Picker';
    private static comparisonToDatePickerSelector = '#ComparisonTo_Picker';

    private static fromDateSelector = '#From';
    private static toDateSelector = '#To';
    private static comparisonFromDateSelector = '#ComparisonFrom';
    private static comparisonToDateSelector = '#ComparisonTo';
    private static comparisonMinYear = 2000;

    constructor($container: JQuery, isClientAdmin: boolean, ) {
        this.$container = $container;
        this.isClientAdmin = isClientAdmin;
        this.isCustomSearch = false;
    }

    updateChart(newChart: morris.GridChart) {
        this.chart = newChart;
    }

    applyEvents(): void {
        var options: DatepickerOptions = Datepicker.defaultDatepickerOptions;

        Datepicker.applyDatepicker($(BreakdownReportSearch.fromDatePickerSelector), options);
        Datepicker.applyDatepicker($(BreakdownReportSearch.toDatePickerSelector), options);
        Datepicker.applyDatepicker($(BreakdownReportSearch.comparisonFromDatePickerSelector), options);
        Datepicker.applyDatepicker($(BreakdownReportSearch.comparisonToDatePickerSelector), options);
        
        $(BreakdownReportSearch.fromDatePickerSelector).on('hide', (e) => {
            var $target = $(e.currentTarget);
            var newDate = DateUtilities.getDateFromDayMonthYear($target.val() as string);
            this.updateCurrentDates(newDate, undefined);
            this.isCustomSearch = true;
            this.triggerSearchSubmit();
        });
        $(BreakdownReportSearch.toDatePickerSelector).on('hide', (e) => {
            var $target = $(e.currentTarget);
            var newDate = DateUtilities.getDateFromDayMonthYear($target.val() as string);
            this.updateCurrentDates(undefined, newDate);
            this.isCustomSearch = true;
            this.triggerSearchSubmit();
        });
        $(BreakdownReportSearch.comparisonFromDatePickerSelector).on('hide', (e) => {
            var $target = $(e.currentTarget);
            var newDate = DateUtilities.getDateFromDayMonthYear($target.val() as string);
            this.updateComparisonDates(newDate, undefined);
            this.isCustomSearch = true;
            this.triggerSearchSubmit();
        });
        $(BreakdownReportSearch.comparisonToDatePickerSelector).on('hide', (e) => {
            var $target = $(e.currentTarget);
            var newDate = DateUtilities.getDateFromDayMonthYear($target.val() as string);
            this.updateComparisonDates(undefined, newDate);
            this.isCustomSearch = true;
            this.triggerSearchSubmit();
        });

        $('#SelectedPeriod', this.$container).on('change', e => {
            var $target = $(e.currentTarget);
            var period = parseInt($target.val() as string);

            var dateRange = this.getPresetDateRange(period);

            this.updateCurrentDates(dateRange.from, dateRange.to, true);
            this.calculateAndUpdateComparisonDatesFromCurrentDates(dateRange.from, dateRange.to);
            this.isCustomSearch = false;
            this.triggerSearchSubmit();
        });
        $('#SelectedComparisonPeriod', this.$container).on('change', e => {
            var fromDate = new Date(this.getFrom());
            var toDate = new Date(this.getTo());
            this.calculateAndUpdateComparisonDatesFromCurrentDates(fromDate, toDate);
            this.isCustomSearch = false;
            this.triggerSearchSubmit();
        });
        $('#SelectedLocation,#SelectedClientSite', this.$container).on('change', e => {
            this.triggerSearchSubmit();
        });
        $('#SelectedCompareLocation,#SelectedCompareClientSite', this.$container).on('change', e => {
            this.triggerSearchSubmit();
        });
    }

    private calculateAndUpdateComparisonDatesFromCurrentDates(currentFromDate: Date, currentToDate: Date) {
        var yearDifference = this.getYearDifference();
        if (yearDifference > 0) {
            this.updateComparisonDates(DateUtilities.addYears(currentFromDate, -yearDifference),
                DateUtilities.addYears(currentToDate, -yearDifference),
                true);
        } else {
            var comparisonPeriod = this.getComparisonPeriod();
            //var fromDate: Date;
            //var toDate: Date;
            //switch (comparisonPeriod) {
            //    case 1:
            //        fromDate = DateUtilities.addDays(new Date(), -1);
            //        toDate = new Date(fromDate);
            //        break;
            //    case 2:
            //        fromDate = DateUtilities.addDays(new Date(), -2);
            //        toDate = new Date(fromDate);
            //        break;
            //    case 3:
            //        fromDate = DateUtilities.addDays(DateUtilities.getMonday(new Date()), -7);
            //        toDate = DateUtilities.addDays(new Date(fromDate), 6);
            //        break;
            //    case 4:
            //        fromDate = DateUtilities.addDays(new Date(), -14);
            //        toDate = DateUtilities.addDays(new Date(fromDate), 6);
            //        break;
            //    case 5:
            //        toDate = DateUtilities.addDays(DateUtilities.getFirstDayOfMonth(new Date()), -1);
            //        fromDate = DateUtilities.getFirstDayOfMonth(toDate);
            //        break;
            //    case 6:
            //        toDate = DateUtilities.addDays(new Date(), -31);
            //            fromDate = DateUtilities.addDays(new Date(toDate), -30);
            //        break;
            //    case 7:
            //        toDate = DateUtilities.addDays(DateUtilities.getFirstDayOfYear(new Date()), -1);
            //        fromDate = DateUtilities.getFirstDayOfYear(toDate);
            //        break;
            //    case 8:
            //        fromDate = DateUtilities.addMonths(new Date(), -24);
            //        toDate = DateUtilities.addDays(DateUtilities.addMonths(new Date(), -12), -1);    
            //        break;
            //    default:
            //        toDate = currentToDate;
            //        fromDate = currentFromDate;
            //        Logger.error("Unable to find a date setting for value: " +
            //            (comparisonPeriod) +
            //            ", in the Activity Log report");
            //        break;
            //}
            var dateRange = this.getPresetDateRange(comparisonPeriod);
            this.updateComparisonDates(dateRange.from, dateRange.to, true);
        }
    }

    currentYear(): string {
        return new Date(this.getFrom()).getFullYear().toString();
    }
    comparisonYear(): string {
        return this.getComparisonYear().toString();
    }

    getQueryString(): string {
        var qs = '?from=' + this.getFrom() +
            '&to=' + this.getTo() +
            '&comparisonFrom=' + this.getComparisonFrom() +
            '&comparisonTo=' + this.getComparisonTo();

        if (this.isClientAdmin) {
            qs = qs + '&clientSiteId=' + this.getClientSiteId() + '&comparisonClientSiteId=' + this.getComparisonClientSiteId();
        } else {
            qs = qs + '&location=' + this.getLocation() + '&comparisonLocation=' + this.getComparisonLocation();
        }

        return qs;
    }

    getLocation(): string {
        return $('#SelectedLocation', this.$container).val() as string;
    }
    getComparisonLocation(): string {
        return $('#SelectedCompareLocation', this.$container).val() as string;
    }
    getLocationText(): string {
        return $('#SelectedLocation :selected', this.$container).text() as string;
    }
    getComparisonLocationText(): string {
        return $('#SelectedCompareLocation :selected', this.$container).text() as string;
    }
    getClientSiteId(): string {
        return $('#SelectedClientSite', this.$container).val() as string;
    }
    getComparisonClientSiteId(): string {
        return $('#SelectedCompareClientSite', this.$container).val() as string;
    }
    getClientSiteName(): string {
        return $('#SelectedClientSite :selected', this.$container).text() as string;
    }
    getComparisonClientSiteName(): string {
        return $('#SelectedCompareClientSite :selected', this.$container).text() as string;
    }
    getFrom(): string {
        return $(BreakdownReportSearch.fromDateSelector, this.$container).val() as string;
    }
    getTo(): string {
        return $(BreakdownReportSearch.toDateSelector, this.$container).val() as string;
    }
    getComparisonFrom(): string {
        return $(BreakdownReportSearch.comparisonFromDateSelector, this.$container).val() as string;
    }
    getComparisonTo(): string {
        return $(BreakdownReportSearch.comparisonToDateSelector, this.$container).val() as string;
    }
    getPeriodText(): string {
        return $('#SelectedPeriod :selected', this.$container).text() as string;
    }
    getComparisonPeriodText(): string {
        return $('#SelectedComparisonPeriod :selected', this.$container).text() as string;
    }

    getPeriodTitle(): string {
        var text: string;
        if (this.isClientAdmin) {
            text = this.getClientSiteName();
        } else {
            text = this.getLocationText();
        }
        if (this.isCustomSearch) {
            text += ', ' + moment(this.getFrom().toString()).format("DD/MM/YYYY") + ' - ' + moment(this.getTo().toString()).format("DD/MM/YYYY");
        } else {
            text += ', ' + this.getPeriodText();
        }
        return text;
    }

    getCompareTitle(): string {
        var text: string;
        if (this.isClientAdmin) {
            text = this.getComparisonClientSiteName();
        } else {
            text = this.getComparisonLocationText();
        }
        if (this.isCustomSearch) {
            text += ', ' + moment(this.getComparisonFrom().toString()).format("DD/MM/YYYY") + ' - ' + moment(this.getComparisonTo().toString()).format("DD/MM/YYYY");
        } else {
            text += ', ' + this.getComparisonPeriodText();
        }
        return text;
    }

    private triggerSearchSubmit(): void {
        //this.updateSearchHeading();
        this.$container.trigger(BreakdownReportSearch.breakdownReportSubmitEvent);
    }

    //private updateSearchHeading(): void {
    //    var fromText = moment(this.getFrom().toString()).format("DD/MM/YYYY");
    //    var toText = moment(this.getTo().toString()).format("DD/MM/YYYY");
    //    var compFromText = moment(this.getComparisonFrom().toString()).format("DD/MM/YYYY");
    //    var compToText = moment(this.getComparisonTo().toString()).format("DD/MM/YYYY");
    //    var headingText = `Comparing date ranges ${fromText} - ${toText} with ${compFromText} - ${compToText}`;
    //    $('#reportHeadingText', this.$container).text(headingText);
    //}

    // Gets the difference in years between the current year and the year selected by the user in the 
    // comparison period dropdown (if they've selected a year)
    private getYearDifference(): number {
        var thisYear = new Date().getFullYear();
        var compYear = this.getComparisonYear();
        if (compYear > 0) {
            return thisYear - compYear;
        }
        return 0;
    }

    // The comparison period dropdown can contain years or preset period ranges.  If the user has selected a year
    // this will return it, otherwise 0
    private getComparisonYear(): number {
        var selectedVal = parseInt($('#SelectedComparisonPeriod', this.$container).val() as string);
        if (selectedVal >= BreakdownReportSearch.comparisonMinYear) {
            return selectedVal;
        }
        return 0;
    }

    // The comparison period dropdown can contain years or preset period ranges.  If the user has selected a period
    // this will return it, otherwise 0
    private getComparisonPeriod(): number {
        var selectedVal = parseInt($('#SelectedComparisonPeriod', this.$container).val() as string);
        if (selectedVal < BreakdownReportSearch.comparisonMinYear) {
            return selectedVal;
        }
        return 0;
    }

    private getPresetDateRange(period: number): IDateRange {
        var toDate = new Date();
        var fromDate: Date;
        // Use enum
        switch (period) {
        case 1: // Today
            fromDate = new Date();
            toDate = new Date();
            break;
        case 2: // Yesterday
            fromDate = DateUtilities.addDays(new Date(), -1);
            toDate = new Date(fromDate);
            break;
        case 3: // PreviousDay
            fromDate = DateUtilities.addDays(new Date(), -2);
            toDate = new Date(fromDate);
            break;
        case 4: // ThisWeek
            fromDate = DateUtilities.getMonday(new Date());
            toDate = new Date();
            break;
        case 5: // LastWeek
            fromDate = DateUtilities.addDays(DateUtilities.getMonday(new Date()), -7);
            toDate = DateUtilities.addDays(new Date(fromDate), 6);
            break;
        case 6: // LastSevenDays
            fromDate = DateUtilities.addDays(new Date(), -7);
            toDate = DateUtilities.addDays(new Date(), -1);
            break;
        case 7: // PreviousSevenDays
            fromDate = DateUtilities.addDays(new Date(), -14);
            toDate = DateUtilities.addDays(new Date(fromDate), 6);
            break;
        case 8: // ThisMonth
            fromDate = DateUtilities.getFirstDayOfMonth(new Date());
            toDate = new Date();
            break;
        case 9: // LastMonth
            toDate = DateUtilities.addDays(DateUtilities.getFirstDayOfMonth(new Date()), -1);
            fromDate = DateUtilities.getFirstDayOfMonth(toDate);
            break;
        case 10: // LastThirtyDays
            fromDate = DateUtilities.addDays(new Date(), -30);
            toDate = DateUtilities.addDays(new Date(), -1);
            break;
        case 11: // PreviousThirtyDays
            toDate = DateUtilities.addDays(new Date(), -31);
            fromDate = DateUtilities.addDays(new Date(toDate), -30);
            break;
        case 12: // ThisYear
            fromDate = DateUtilities.getFirstDayOfYear(new Date());
            toDate = new Date();
            break;
        case 13: // LastYear
            toDate = DateUtilities.addDays(DateUtilities.getFirstDayOfYear(new Date()), -1);
            fromDate = DateUtilities.getFirstDayOfYear(toDate);
            break;
        case 14: // LastTwelveMonths
            fromDate = DateUtilities.addMonths(new Date(), -12);
            toDate = DateUtilities.addDays(new Date(), -1);
            break;;
        case 15: // PreviousTwelveMonths
            fromDate = DateUtilities.addMonths(new Date(), -24);
            toDate = DateUtilities.addDays(DateUtilities.addMonths(new Date(), -12), -1);
            break;
        default:
            fromDate = new Date();
            toDate = new Date();
            Logger.error("Unable to find a date setting for value: " + (period) + ", in the Activity Log report");
            break;
        }
        return { from: fromDate, to: toDate };
    }

    private updateCurrentDates(from?: Date, to?: Date, updateDatePicker: boolean = false): void {
        if (from !== undefined) {
            var fromDate = DateUtilities.getYearMonthDateFormat(from);
            $(BreakdownReportSearch.fromDateSelector, this.$container).val(fromDate);
            if (updateDatePicker)
                $(BreakdownReportSearch.fromDatePickerSelector).datepicker('update', from);
            Logger.info('Updating current from date.', fromDate);
        }
        if (to !== undefined) {
            var toDate = DateUtilities.getYearMonthDateFormat(to);
            $(BreakdownReportSearch.toDateSelector, this.$container).val(toDate);
            if (updateDatePicker)
                $(BreakdownReportSearch.toDatePickerSelector).datepicker('update', to);
            Logger.info('Updating current to date.', toDate);
        }
    }
    private updateComparisonDates(comparisonFrom?: Date, comparisonTo?: Date, updateDatePicker: boolean = false): void {
        if (comparisonFrom !== undefined) {
            var fromDate = DateUtilities.getYearMonthDateFormat(comparisonFrom);
            $(BreakdownReportSearch.comparisonFromDateSelector, this.$container).val(fromDate);
            if (updateDatePicker)
                $(BreakdownReportSearch.comparisonFromDatePickerSelector).datepicker('update', comparisonFrom);
            Logger.info('Updating comparison from date.', fromDate);
        }
        if (comparisonTo !== undefined) {
            var toDate = DateUtilities.getYearMonthDateFormat(comparisonTo);
            $(BreakdownReportSearch.comparisonToDateSelector, this.$container).val(toDate);
            if (updateDatePicker)
                $(BreakdownReportSearch.comparisonToDatePickerSelector).datepicker('update', comparisonTo);
            Logger.info('Updating comparison to date.', toDate);
        }
    }

    //private toggleCustomSearch(display: boolean): void {
    //    var $dateSearch = $('#datesSearch');
    //    var isVisible = $dateSearch.is(':visible');
    //    this.isCustomSearch = display;
    //    if (isVisible === display)
    //        return;

    //    $dateSearch.slideToggle("fast");
    //    $('#SelectedComparisonPeriod').prop('disabled', display);
    //    $('.comparisonChartContainer').toggleClass('customDates');
    //    $('#activityLogBreakdown svg').css('height', display ? '500px' : '580px');
    //    if (this.chart !== undefined)
    //        this.chart.redraw();
    //}
}

interface IDateRange {
    to: Date;
    from: Date;
}