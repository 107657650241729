import "jquery-validation";
import "jquery-validation-unobtrusive";
import "bootstrap/js/dist/tab";

export class FormUtilities {
	static formTabErrorEvent = 'tabChangeError';

	static validateForm($form: JQuery<HTMLElement>): Promise<boolean> {
		const dfd = $.Deferred<boolean>();
		if ($form.length === 0) {
			throw "ValidateForm called on empty JQuery object";
		}
		console.log('In validateForm() b4 this.validate');
		FormUtilities.validate($form)
			.then(isFormValid => {
				if (!isFormValid) {
					FormUtilities.showTabWhichHasError($form);
				}
				console.log('In validateForm() in then');
				return dfd.resolve(isFormValid);
			});
		console.log('In validateForm() after this.validate');
		return dfd.promise();
	}

	static showValidationErrorForField($element: JQuery<HTMLElement>, err: string): void {
		var field = <string>$element.attr('name');
		var $errorMessageContainer = $element.siblings('.field-validation-valid[data-valmsg-for="' + field + '"]');

		if ($errorMessageContainer.length === 0) {
			if ($element.parent().hasClass('input-group'))
				$errorMessageContainer = $element.parent().siblings('.field-validation-valid[data-valmsg-for="' + field + '"]');
			if ($errorMessageContainer.length === 0)
				console.error(`Unable to find error message container for ${field}.`);
		}

		$element.addClass('input-validation-error');
		$errorMessageContainer.addClass('field-validation-error').removeClass('field-validation-valid');
		$errorMessageContainer.html('<span id="' + field + '-error" class="">' + err + '</span>');
	}
	static clearValidationErrorForField($element: JQuery<HTMLElement>): void {
		var field = <string>$element.attr('name');
		var $errorMessageContainer = $element.siblings('.field-validation-error[data-valmsg-for="' + field + '"]');

		if ($errorMessageContainer.length === 0) {
			if ($element.parent().hasClass('input-group'))
				$errorMessageContainer = $element.parent().siblings('.field-validation-error[data-valmsg-for="' + field + '"]');
			if ($errorMessageContainer.length === 0)
				console.error(`Unable to find error message container for ${field}.`);
		}

		$element.removeClass('input-validation-error');
		$errorMessageContainer.removeClass('field-validation-error').addClass('field-validation-valid');
		$errorMessageContainer.html('');
	}

	static showTabWhichHasError($form?: JQuery<HTMLElement>): void {
		var $invalidItem = $form != undefined ? $('.input-validation-error', $form) : $('.input-validation-error');
		if ($invalidItem) {
			var id = $invalidItem.closest('.tab-pane').attr('id') || '';
			if (id !== '') {
				$('.nav a[href="#' + id + '"]').click();
				if ($form != undefined) {
					$form.trigger(FormUtilities.formTabErrorEvent, { tabId: id });
				}
			}
		}
	}

	static resetFormValidation($form: JQuery<HTMLElement>) {
		try {
			$form.removeData("validator");
			$form.removeData("unobtrusiveValidation");
			$.validator.unobtrusive.parse($form);
		} catch (err) {
			console.warn("unable to reset form validation", err);
		}
	}

	static clearFormValidation($form: JQuery<HTMLElement>) {
		var validator = $form.validate();
		validator.resetForm();
	}

	static styleAsReadonly($form: JQuery<HTMLElement>) {
		$form.find('select:not([multiple])').each(function () {
			var $element = $(<any>this);
			var $parent = $element.parent();
			var $option = $element.find('option:selected');
			var altSelectText = $option.val() ? $option.text() : "";
			var $altSelect = $('<input></input>').val(altSelectText).addClass('form-control');
			$parent.append($altSelect);
			$element.hide();
		});

		$form.find('select, input, textarea').each(function () {
			var $elem = $(<any>this);
			if (!$elem.is('[readonly]'))
				$elem.attr('readonly', 'true');
		});
	}

	static noAutoFill($selection: JQuery) {
		$selection.prop('readonly', true).addClass('noAutoFill').on('focus touchstart', function () {
			this.removeAttribute('readonly');
		}).blur(function () {
			this.setAttribute('readonly', 'readonly');
		});
	}

	private static formValidateTimeout: number;
	private static validateCounter = 0;

	// Carries out form validation as a promise
	private static validate($form: JQuery): Promise<boolean> {
		const dfd = $.Deferred<boolean, any, any>();
		console.log('In validate() b4 this.validateCheck');

		console.log('validate');
		const validator = $form.validate();
		const anyValidator = validator as any;
		validator.settings.ignore = '.validateIgnore,[name="Id"],input[type="hidden"]';

		FormUtilities.validateCounter = 0;
		FormUtilities.validateCheck($form, dfd, anyValidator);

		return dfd.promise();
	}

	// Waits for any remote validators to return
	private static validateCheck($form: JQuery, dfd: JQueryDeferred<boolean>, validator: any) {
		FormUtilities.validateCounter++;
		console.log('validateCheck' + FormUtilities.validateCounter);
		//const validator = $form.validate({
		//	debug: true
		//});
		//const anyValidator = validator as any;
		//validator.settings.ignore = '.validateIgnore,[name="Id"],input[type="hidden"]';
		const isValid = $form.valid();
		console.log('pending ' + validator.pendingRequest);
		const isPending = validator.pendingRequest > 0;

		if (!isPending) {
			if (typeof FormUtilities.formValidateTimeout !== "undefined") {
				console.log('clearing timeout');
				clearTimeout(FormUtilities.formValidateTimeout);
				console.log('cleared timeout');
			}
			console.log('resolving. Validator: ' + isValid);
			dfd.resolve(isValid);
		} else {
			console.log('before setTimeout' + FormUtilities.validateCounter);
			FormUtilities.formValidateTimeout = setTimeout(FormUtilities.validateCheck, 250, $form, dfd, validator);
			console.log('after setTimeout');
		}
	}
}


