import * as SignalR from "@aspnet/signalr";
import { DogfishNotification } from "./NotificationUtilities";
import { IClientEvent } from "../ViewModels";
import { IMultiEventHandler, MultiEventHandler } from "./EventUtilities";
import { Logger } from "./Logger";

export class Subscriptions {
    private static _connection?: SignalR.HubConnection;
    private static listeners: string[] = [];
    private static eventHandler: IMultiEventHandler<any, void> = new MultiEventHandler<any, void>();

    static subscribeToEvent<T>(name: string, callback: (e: T) => void) {
        if (Subscriptions.isIE()) {
            console.warn('SignalR is not supported in IE.');
            return;
        }

        this.eventHandler.add(name, callback);
        var hubMethod = HubMethods.event;
        if (this.listeners.indexOf(hubMethod) >= 0)
            return;

        this.listeners.push(hubMethod);

        var connection = this.getConnection();

        connection.on(hubMethod, (e: IClientEvent) => {
            Logger.debug("Event method running", hubMethod, e);
            this.eventHandler.run(e.type, e);
        });
    }

    static subscribeToBrowserNotifications() {
        if (Subscriptions.isIE()) {
            console.warn('SignalR is not supported in IE.');
            return;
        }

        var hubMethod = HubMethods.browserNotification;
        if (this.listeners.indexOf(hubMethod) >= 0)
            return;

        this.listeners.push(hubMethod);

        var connection = this.getConnection();

        connection.on(hubMethod, message => {
            Logger.debug("Browser notification incoming", hubMethod, message);
            DogfishNotification.windowMessage(message);
        });
    }

    // Wont be unique if included in multiple bundles (e.g. called from global)
    private static getConnection(): SignalR.HubConnection {
        if (this._connection !== undefined)
            return this._connection;

        this._connection = new SignalR.HubConnectionBuilder()
            .withUrl("/dogfishHub")
            .build();

		this._connection.start().catch(err => Logger.warning("Error during SignalR connection. Error: " + err.toString(), err.toString()));

        return this._connection;
    }

    //Taken from is.js (http://is.js.org/)
    private static isIE() {
        var userAgent = (navigator && navigator.userAgent || '').toLowerCase();
        var match = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
        return match !== null;
    };
}

class HubMethods {
    public static browserNotification: string = "BrowserNotification";
    public static event: string = "Event";
}