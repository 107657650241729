import { ModalBase } from "./ModalBase";
import { FormModal } from "./FormModal";
import { IAjaxError } from "../../dogfish.server.interfaces";

export class SimpleModal extends ModalBase {
    constructor($modal: JQuery<HTMLElement>, enableLogging = false) {
        super($modal, enableLogging);
    }

    postReload(): void {
        // do nothing
    }
}

export class SimpleFormModal extends FormModal {
    private _onSuccess?: (data: any) => void;
    private _onError?: (err: IAjaxError) => void;

    constructor($modal: JQuery<HTMLElement>, enableLogging = false, onSuccess?: (data: any) => void, onError?: (err: IAjaxError) => void) {
        super($modal, enableLogging);

        this._onSuccess = onSuccess;
        this._onError = onError;
    }

    onSuccess(data: any): void {
        super.onSuccess(data);
        if (this._onSuccess)
            this._onSuccess(data);
    }

    onError(err: IAjaxError): void {
        super.onError(err);
        if (this._onError)
            this._onError(err);
    }
}