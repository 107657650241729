import { Logger } from "../Logger";

export class ModalStacker {
    private static openModals: number = 0;

    static addModal($modal: JQuery<HTMLElement>): void {
        if ($modal.hasClass('pcg-modal-stack')) {
            return;
        }

        $modal.addClass('pcg-modal-stack');
        this.openModals++;

        const zIndex = this.getZIndex();
        $modal.css('z-index', zIndex.modalZIndex);
        $('.modal-backdrop').not('.pcg-modal-stack')
            .css('z-index', zIndex.modalBackdropZIndex)
            .data('zindex', zIndex.modalBackdropZIndex)
            .addClass('pcg-modal-stack');

        //this.log('Creating modal with zIndex: ' + zIndex.modalZIndex + ' and backdrop with zIndex: ' + zIndex.modalBackdropZIndex);
    }
    static removeModal($modal: JQuery<HTMLElement>): void {
        if (!$modal.hasClass('pcg-modal-stack')) {
            return;
        }

        $modal.removeClass('pcg-modal-stack');
        const zIndex = ModalStackerInfo.getFromModal($modal);
        const backdrop = this.getBackdrop(zIndex.modalBackdropZIndex);
        backdrop.remove();
        this.openModals--;

        //this.log('Removing modal with zIndex: ' + zIndex.modalZIndex + ' and backdrop with zIndex: ' + zIndex.modalBackdropZIndex);
    }


    static modalHidden(): void {
        const $body = $('body');
        if (this.openModals > 0 && !$body.hasClass('modal-open')) {
            $body.addClass('modal-open');
            $body.css('padding-right', '17px');
        }
    }

    private static getZIndex(): ModalStackerInfo {
        const modalZIndex = 1040 + (10 * this.openModals);
        return new ModalStackerInfo(modalZIndex, modalZIndex - 1);
    }
    private static getBackdrop(zIndex: number): JQuery<HTMLElement> {
        return $('.modal-backdrop.pcg-modal-stack[data-zindex="' + zIndex + '"]');
    }

	private static log(message: string): void {
		Logger.debug(message);
    }
}

class ModalStackerInfo {
    constructor(modalZIndex: number, modalBackdropZIndex: number) {
        this.modalZIndex = modalZIndex;
        this.modalBackdropZIndex = modalBackdropZIndex;
    }
    static getFromModal($modal: JQuery<HTMLElement>): ModalStackerInfo {
        const zindex = +$modal.css('z-index');
        return new ModalStackerInfo(zindex, zindex - 1);
    }

    modalZIndex: number;
    modalBackdropZIndex: number;
}