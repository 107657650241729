import { Logger } from "./Logger";
//import "select2/dist/js/select2.min";
import "select2";


export class SelectUtilities {
    static getSelected($select: JQuery<HTMLElement>): HTMLElement[] {
        var result: HTMLElement[] = [];
        $select.find('option:selected').each(function () {
            result.push(<any>this);
        });

        return result;
    }

    static copySelectedItems($fromSelectBox: JQuery<HTMLElement>, $toSelectBox: JQuery<HTMLElement>, copyType: 'remove' | 'hide' = 'remove'): JQuery<HTMLElement> {
        var selectedItems = $('option:selected', $fromSelectBox);
        var $selectedItemsCopy = $(selectedItems).clone();

        if (copyType === 'remove') {
            $toSelectBox.append($selectedItemsCopy);
            $(selectedItems).remove();
        }
        else if (copyType === 'hide') {
            $selectedItemsCopy.each(function () {
                var $option = $(<any>this);
                var $existingOption = $toSelectBox.find(`option[value="${$option.val()}"]`);

                if ($existingOption.length)
                    $existingOption.show();
                else
                    $toSelectBox.append($option);
            });

            $(selectedItems).hide();
        }


        return $selectedItemsCopy;
    }

    static copySelectedItemsToDynamic($fromSelectBox: JQuery<HTMLElement>, fn: ($option: JQuery<HTMLElement>) => JQuery<HTMLElement>) {
        var $selectedItems = $('option:selected', $fromSelectBox);
        $selectedItems.each(function () {
            var $item = $(<any>this).clone();
            var $toSelectBox = fn($item);
            var $existingItem = $toSelectBox.find(`option[value="${$item.val()}"]`);
            if (!$existingItem.length)
                $toSelectBox.append($item);
            else
                $existingItem.show();
        });

        $selectedItems.remove();
    }

    static moveSelectedItemsUp($select: JQuery<HTMLElement>) {
        var $selectedItems = $('option:selected', $select);
        if ($selectedItems.length) {
            $selectedItems.first().prev().before($selectedItems);
        }
    }
    static moveSelectedItemsDown($select: JQuery<HTMLElement>) {
        var $selectedItems = $('option:selected', $select);
        if ($selectedItems.length) {
            $selectedItems.last().next().after($selectedItems);
        }
    }


    static addMovementEvents(options: IMultiSelectEventOptions) {
        if (!options.$listBox.length || !options.$selectedItemsBox.length)
            throw new Error("Unable to add events as one of the list boxes is null");

        var listBoxId = options.$listBox.attr('id') as string;
        var selectItemsBoxId = options.$selectedItemsBox.attr('id') as string;
        var addName = options.addClassName || 'add';
        var removeName = options.removeClassName || 'remove';
        var upName = options.upClassName || 'up';
        var downName = options.downClassName || 'down';

        options.$container.on('click', '.' + addName, function () {
            SelectUtilities.copySelectedItems(options.$listBox, options.$selectedItemsBox);
        }).on('dblclick', '#' + listBoxId + ' option', function () {
            SelectUtilities.copySelectedItems(options.$listBox, options.$selectedItemsBox);
        });

        options.$container.on('click', '.' + removeName, function () {
            SelectUtilities.copySelectedItems(options.$selectedItemsBox, options.$listBox);
        }).on('dblclick', '#' + selectItemsBoxId + ' option', function () {
            SelectUtilities.copySelectedItems(options.$selectedItemsBox, options.$listBox);
        });

        options.$container.on('click', '.' + upName, function () {
            SelectUtilities.moveSelectedItemsUp(options.$selectedItemsBox);
        }).on('click', '.' + downName, function () {
            SelectUtilities.moveSelectedItemsDown(options.$selectedItemsBox);
        });
    }

    static addControlEvents(options: ISelectControlOptions) {
        if (!options || !options.$select.length)
            throw new Error("Select element must be provided");

        var $select = options.$select;

        options.$sources.on('dblclick', function (e) {
            e.preventDefault();
            if (!options.getAddSource) {
                Logger.warning("Must specify a source for button");
                return;
            }
            var $source = options.getAddSource($(this));
            SelectUtilities.controlAddEvent(options, $source);
        });

        if (options.$addBtn !== undefined) {
            options.$addBtn.click(function (e) {
                e.preventDefault();
                if (!options.getAddSource) {
                    Logger.warning("Must specify a source for button");
                    return;
                }
                var $source = options.getAddSource($(this));
                SelectUtilities.controlAddEvent(options, $source);
            });
        }

        if (options.$removeBtn !== undefined) {
            $select.on('dblclick', e => {
                e.preventDefault();
                this.controlRemoveEvent(options);
            });

            options.$removeBtn.click(e => {
                e.preventDefault();
                this.controlRemoveEvent(options);
            });
        }

        if (options.$upBtn !== undefined) {
            options.$upBtn.click(e => {
                e.preventDefault();
                SelectUtilities.moveSelectedItemsUp($select);
                $select.trigger('change');
            });
        }

        if (options.$downBtn !== undefined) {
            options.$downBtn.click(e => {
                e.preventDefault();
                SelectUtilities.moveSelectedItemsDown($select);
                $select.trigger('change');
            });
        }
    }

    private static controlRemoveEvent(options: ISelectControlOptions) {
        var $select = options.$select;
        if (options.getRemoveDestination === undefined)
            throw new Error("Must provide getRemoveDestination");

        SelectUtilities.copySelectedItemsToDynamic($select, options.getRemoveDestination);
        $select.trigger('change');
    }

    private static controlAddEvent(options: ISelectControlOptions, $source: JQuery<HTMLElement> | null | undefined) {
        if (!$source) return;

        var $select = options.$select;
        var copyType = options.copyType ? options.copyType : 'remove';

        if (options.getAddSource === undefined)
            throw new Error("Must provide getAddSource");

        if ($source) {
            SelectUtilities.copySelectedItems($source, $select, copyType);
            $select.trigger('change');
        }
    }
}

export interface IMultiSelectEventOptions {
    $container: JQuery<HTMLElement>;
    $listBox: JQuery<HTMLElement>;
    $selectedItemsBox: JQuery<HTMLElement>;

    addClassName?: string;
    removeClassName?: string;
    upClassName?: string;
    downClassName?: string;
}

export interface ISelectControlOptions {
    $select: JQuery<HTMLElement>;
    $sources: JQuery<HTMLElement>;

    $addBtn?: JQuery<HTMLElement>;
    $removeBtn?: JQuery<HTMLElement>;
    $upBtn?: JQuery<HTMLElement>;
    $downBtn?: JQuery<HTMLElement>;

    getAddSource?: ($addBtn: JQuery<HTMLElement>) => JQuery<HTMLElement> | null | undefined;
    getRemoveDestination?: ($option: JQuery<HTMLElement>) => JQuery<HTMLElement>;

    copyType?: 'remove' | 'hide';
}


export class Select2Util {
	static applySelect2($element: JQuery, $parentElement: JQuery): JQuery {
		return $element.select2({
			dropdownParent: $parentElement
		});
	}
}