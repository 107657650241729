import {
	IDataTableColumnVM, DataTableColumnType, ITableAction, ITableList, ITableAction as ITableAction1,
	IViewTableAction,
	IEditTableAction,
	IDeleteTableAction,
	ICustomTableAction,
	ITableMoreText,
	ITableTextIcon,
	ITableTextInput,
	ITableRadioInput,
	ITableDropdown,
    ITableImageButton
} from "../ViewModels";
import { Logger } from "./Logger";

export function defaultColumnDefRender(data: any, type: any, row: any): any {
	if (data === null || typeof (data) === "string" || typeof (data) == "number") {
		return htmlEncode(data);
	}

	var vm = <IDataTableColumnVM>data;
	if (vm === undefined) {
		Logger.warning("undefined field", data, type, row);
		return "";
	}

	var defaultValue = htmlEncode(data[type] || vm.display);
	var customValue = htmlEncode(data[type] || '');

	switch (vm.type) {
		case DataTableColumnType.Default:
			return defaultValue;

		case DataTableColumnType.Actions:
			return (type === "display") ? getActionsHtml(data.display) : customValue;

		case DataTableColumnType.List:
			return (type === "display") ? getListHtml(data.display) : customValue;

		case DataTableColumnType.MoreText:
			return (type === "display") ? getMoreTextHtml(data.display) : customValue;

		case DataTableColumnType.TextIcon:
			return (type === "display") ? getTextIconHtml(data.display) : customValue;

		case DataTableColumnType.TextInput:
			return (type === "display") ? getTextInputHtml(data.display) : customValue;

		case DataTableColumnType.Dropdown:
			return (type === "display") ? getDropdownHtml(data.display) : customValue;

		case DataTableColumnType.RadioInput:
			return (type === "display") ? getRadioInputHtml(data.display) : customValue;

		case DataTableColumnType.ImageButton:
			return (type === "display") ? getImageButtonHtml(data.display) : customValue;

		case DataTableColumnType.ImageButtons:
			return (type === "display") ? getImageButtonsHtml(data.display) : customValue;

		default:
			return defaultValue;
	}
}

export function getActionsHtml(actions: ITableAction[]): string {
	if (actions.length === 0) {
		return "";
	}

	if (actions.length === 1) {
		return getActionHtml(actions[0]);
	}

	var str = "";
	str += '<a class="nav-link dropdown-toggle pl-0 pr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">Action </a>';
	str += '<div class="dropdown-menu dropdown-menu-right profile-dropdown" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1397px, 33px, 0px);">';
	for (var i = 0; i < actions.length; i++) {
		str += getActionHtml(actions[i]);
	}
	str += '</div>';

	return str;
}

export function getImageButtonsHtml(buttons: ITableImageButton[]): string {
	if (buttons.length === 0) {
		return "";
	}

	if (buttons.length === 1) {
		return getImageButtonHtml(buttons[0]);
	}

	var str = '<div class="imageButtons">';
	for (var i = 0; i < buttons.length; i++) {
		str += getImageButtonHtml(buttons[i]);
	}
	str += '</div>';

	return str;
}

function getImageButtonHtml(data: ITableImageButton): string {
	var $icon = $('<i class="tableIcon"></i>').addClass(data.iconClass);
	var $resultContainer = $('<a class="btn btn-default btn-sm imagePreviewButton" target="_blank"></a>');
	if (!data.imageUrl) {
		$resultContainer.addClass('disabled').attr('title', 'Image not available');
	} else {
		$resultContainer.attr('data-toggle', 'tooltip');
		$resultContainer.attr('data-html', 'true');
		$resultContainer.attr('data-placement', 'top');
		var $tooltip = $('<img class="tooltipImage" />');
		$tooltip.attr('src', data.imageUrl);
		$resultContainer.attr('title', $('<div></div>').append($tooltip).html());
	}
	$resultContainer.attr('href', data.imageUrl);
	$resultContainer.text(data.displayText);
	$resultContainer.append($icon);

	return $('<div></div>').append($resultContainer).html();
}

function getTextIconHtml(data: ITableTextIcon): string {
	var $icon = $('<i class="tableIcon"></i>').addClass(data.iconClass).attr('title', data.iconTitle ? data.iconTitle : "");
	var $resultContainer = $('<div></div>');
	$resultContainer.text(data.displayText);
	$resultContainer.append($icon);

	return $resultContainer.html();
}

function getListHtml(data: ITableList): string {
	if (!data.items) return '';

	var $text = $('<span class="text"></span>').text(data.displayItems.join(', ').substr(0, data.displayLength));
	var tooltipHtml = data.items.join('<br/>');
	var $badge: any;
	if (data.items.length > 0) {
		$badge = $('<span class="count badge-pill badge-info" data-toggle="tooltip" data-placement="left" data-html="true"></span>')
			.attr('title', tooltipHtml).text(data.items.length);
	}

	var $result = $('<div class="tableList"></div>');
	$result.append($text);
	if ($badge) $result.append($badge);

	return (<any>$result.get(0)).outerHTML;
}

function getMoreTextHtml(data: ITableMoreText): string {
	var displayText = data.displayText ? data.displayText : "";
	var maxMoreTextLength = 30;

	if (!data.moreText) return htmlEncode(displayText);

	var tooltipAttrs = data.moreText
		? `data-toggle="tooltip" data-tooltip-class="tooltip-lg-scroll" data-placement="bottom" data-html="true" title="${data.moreText.split('\n').map(htmlEncode).join('<br>')}"`
		: '';

	var icon = data.moreText
		? `<i class="fa fa-chevron-circle-down" aria-hidden="true"></i>`
		: '';

	if (!data.displayText) {
		displayText = data.moreText;
		if (displayText.length > maxMoreTextLength) {
			displayText = displayText.substr(0, maxMoreTextLength) + "...";
		} else {
			icon = '';
			tooltipAttrs = '';
		}
	}

	return `<div class="tableMoreText" ${tooltipAttrs}>${htmlEncode(displayText)}${icon}</div>`;
}

function getTextInputHtml(data: ITableTextInput): string {
	var displayText = data.displayText ? data.displayText : "";

	var id = data.id !== undefined && data.id !== null ? `id="${data.id}"` : '';
	var name = data.name !== undefined && data.name !== null ? `name="${data.name}"` : '';
	var placeholder = data.placeholder !== undefined && data.placeholder !== null ? `placeholder="${data.placeholder}"` : '';
	var readonlyAttr = data.isReadonly ? 'readonly' : '';

	return `<input class="form-control" type="text" value="${htmlEncode(displayText)}" ${id}${name}${placeholder} ${readonlyAttr} />`;
}

function getRadioInputHtml(data: ITableRadioInput): string {
	var radios = '';
	var disabledAttr = data.isReadonly ? 'disabled' : '';
	data.options.forEach((item, index) => {
		var keyId = data.id !== undefined && data.id !== null ? data.id : index;
		var id = keyId + '_' + item.value;
		var checkedAttr = data.selectedValue == item.value ? 'checked' : '';
		radios += `<div class="radio radio-primary form-check-inline"><input id="${id}" name="${data.name}" value="${item.value}" type="radio" ${checkedAttr} ${disabledAttr} /><label for="${id}" class= "mt-1" >${item.label}</label></div>`;
	});

	return `<div>${radios}</div>`;
}

function getDropdownHtml(data: ITableDropdown): string {
	var options = '';
	if (data.includeChoose) {
		options += `<option value="">Choose...</option>`;
	}
	data.options.forEach((item, index) => {
		var selectedAttr = item.value == data.selectedValue ? 'selected' : '';
		options += `<option value="${item.value}" ${selectedAttr}>${item.label}</option>`;
	});

	var idAttr = data.id !== undefined && data.id !== null ? `id="${data.id}"` : '';
	var readonlyAttr = data.isReadonly ? 'readonly' : '';
	return `<select ${idAttr} name="${data.name}" class="form-control" value="" ${readonlyAttr}>${options}</select>`;
}

function getActionHtml(actionBase: ITableAction1): string {
	switch (actionBase.actionType) {
		case "View":
			var viewAction = <IViewTableAction>actionBase;
			return `<a href="${viewAction.href || '#'}" target="${viewAction.target}" class="dropdown-item notify-item view" data-id="${viewAction.id}"><i class="fa fa-eye" aria-hidden="true"></i>${viewAction.text}</a>`;
		case "Edit":
			var editAction = <IEditTableAction>actionBase;
			var dataAttrStr = actionDataToAttributeString(editAction.data);
			return `<a href="#" class="dropdown-item notify-item edit openModal" data-id="${editAction.id}" ${dataAttrStr}><i class="fa fa-pencil-square-o" aria-hidden="true"></i>${editAction.text}</a>`;
		case "Delete":
			var deleteAction = <IDeleteTableAction>actionBase;
			var dataAttrStr = actionDataToAttributeString(deleteAction.data);
			return `<a href="#" class="dropdown-item notify-item delete" data-id="${deleteAction.id}" data-undelete=${deleteAction.isUndelete} data-entity-name="${deleteAction.entityName || ""}" data-confirm-message="${deleteAction.confirmMessage || ""}" ${dataAttrStr}><i class="fa fa-trash" aria-hidden="true"></i>${deleteAction.text}</a>`;
		case "Custom":
			var customAction = <ICustomTableAction>actionBase;
			var dataAttrStr = actionDataToAttributeString(customAction.data);
			return `<a href="${customAction.href || '#'}" data-ajax-method="${customAction.ajaxMethod}" data-ajax-success-message="${customAction.ajaxSuccessMessage}" target="${customAction.target}" class="dropdown-item notify-item ${customAction.cssClass}" data-id="${customAction.id}" ${dataAttrStr}><i class="${customAction.iconClass || "fa fa-bolt"}" aria-hidden="true"></i>${customAction.text}</a>`;
		default:
			Logger.warning("Unsupported action type: " + actionBase.actionType, actionBase);
			break;
	}

	return "";
}

function actionDataToAttributeString(data: any): string {
	if (!data) return "";

	var keys = Object.keys(data);
	var result = "";
	for (var i = 0; i < keys.length; i++) {
		var key = keys[i];
		result += `data-${key.toLowerCase().replace('_', '-')}="${data[key]}" `;
	}

	return result;
}

function htmlEncode(data: string): string {
	return $('<div />').text(data).html();
}