export interface IReAuthRequest {
    userId?: string;
    password?: string;
    authorizationFor: string;
    isHidden: boolean;
}

export interface IReAuthResponse {
    token: string;
}

export interface IAjaxResponse {
    success: boolean;
    data: any;
    message?: string;
}

export interface IAjaxReponseData {
	message: string
}

export interface IAjaxError {
    isHandled: boolean;
    message?: string;
}

export interface IFileUploadStrategyVM {
	isDirectUploadRequired: boolean,
	url: string,
	awsStorageInfo: any,
	maxFileSize: string,
	maxFileSizeInBytes: number | null
}

export enum FileUploadVMType {
	InitialRequest = 0,
	RemoteFileUploadConfirmation = 1,
	DirectFileUpload = 2
}

export interface IRemoteUploadResult {
	successful: boolean;
}