import { FormUtilities } from "./FormUtilities";
import { FormDataUtilities } from "./Utilities";
import { ReAuthModal } from "./Modal/ReAuthModal";
import { IReAuthRequest, IReAuthResponse, IAjaxError, IAjaxResponse, IAjaxResponse as IAjaxResponse1 } from "../dogfish.server.interfaces";
import { SystemUrls } from "../ViewModels";
import { Dialog } from "./DialogUtilities";
import { Logger } from "./Logger";

export class AjaxError implements IAjaxError {
	constructor(public isHandled: boolean, public message?: string) { }
}

export class AjaxUrl {
	public readonly $element: JQuery<HTMLElement>;

	constructor($element: JQuery<HTMLElement>) {
		this.$element = $element;
	}

	public url(): string {
		return this.$element.data('url');
	}

	public isReAuthRequired(): boolean {
		return AjaxRequest.requiresReAuth(this.$element);
	}
}

export class AjaxRequest {
	//static getRequestNoCache<T>(url: string): Promise<T> {
	//    var dfd = AjaxRequest.GetDeferred<T>();
	//    var promise = dfd.promise();

	//    $.ajax({
	//        url: url,
	//        method: "GET",
	//        cache: false,
	//        success(response: IAjaxResponse) {
	//            AjaxRequest.handleSuccess<T>(response, dfd);
	//        },
	//        error(request, textStatus, errorThrown) {
	//            AjaxRequest.handleError(request, textStatus, errorThrown, dfd);
	//        }
	//    });

	//    return promise;
	//}

	static getRequest<T>(url: string, handleError: boolean = true, enableCache = false): Promise<T> {
		var dfd = AjaxRequest.GetDeferred<T>();
		var promise = dfd.promise();

		$.ajax({
			url: url,
			method: "GET",
			cache: enableCache,
			success(response: IAjaxResponse) {
				AjaxRequest.handleSuccess<T>(response, dfd);
			},
			error(request, textStatus, errorThrown) {
				if (handleError)
					AjaxRequest.handleError(request, textStatus, errorThrown, dfd);
				else
					dfd.reject(new AjaxError(false));
			}
		});

		return promise;
	}

	static postRequestPromise<T>(url: string, dataToPost?: any, reAuthToken?: string, $form?: JQuery<HTMLElement>): Promise<T> {
		var dfd = AjaxRequest.GetDeferred<T>();
		var promise = dfd.promise();

		$.ajax({
			url: url,
			method: "POST",
			data: dataToPost,
			beforeSend(xhr) {
				if (reAuthToken) {
					xhr.setRequestHeader('Authorization', `Bearer ${reAuthToken}`);
				}
			},
			success(response: IAjaxResponse) {
				AjaxRequest.handleSuccess<T>(response, dfd);
			},
			error(request, textStatus, errorThrown) {
				AjaxRequest.handleError(request, textStatus, errorThrown, dfd, $form);
			}
		});

		return promise;
	}
	static uploadFilePromise<T>(url: string, dataToPost?: any, reAuthToken?: string, $form?: JQuery<HTMLElement>): Promise<T> {
		var dfd = AjaxRequest.GetDeferred<T>();
		var promise = dfd.promise();

		$.ajax({
			url: url,
			method: "POST",
			data: dataToPost,
			processData: false,
			contentType: false,
			beforeSend(xhr) {
				if (reAuthToken) {
					xhr.setRequestHeader('Authorization', `Bearer ${reAuthToken}`);
				}
			},
			success(response: IAjaxResponse) {
				AjaxRequest.handleSuccess<T>(response, dfd);
			},
			error(request, textStatus, errorThrown) {
				AjaxRequest.handleError(request, textStatus, errorThrown, dfd, $form);
			}
		});

		return promise;
	}

	static customRequest<T>(settings: any, reAuthToken?: string, $form?: JQuery<HTMLElement>): Promise<T> {
		var dfd = AjaxRequest.GetDeferred<T>();
		var promise = dfd.promise();

		if (reAuthToken) {
			settings.beforeSend = (xhr: any) => {
				xhr.setRequestHeader('Authorization', `Bearer ${reAuthToken}`);
			}
		}
		settings.success = (response: IAjaxResponse1) => {
			AjaxRequest.handleSuccess<T>(response, dfd);
		};
		settings.error = (request: any, textStatus: any, errorThrown: any) => {
			AjaxRequest.handleError(request, textStatus, errorThrown, dfd, $form);
		};
		$.ajax(settings);

		return promise;
	}

	static externalRequest<T>(settings: any): Promise<T> {
		var dfd = AjaxRequest.GetDeferred<T>();
		var promise = dfd.promise();

		settings.success = (response: IAjaxResponse1) => {
			dfd.resolve(response.data);
		};
		settings.error = (request: any, textStatus: any, errorThrown: any) => {
			dfd.reject(request, textStatus, errorThrown);
		};
		$.ajax(settings);

		return promise;
	}

	static postForm<T>($form: JQuery, isJson: boolean = false, isFile: boolean = false): Promise<T> {
		return FormUtilities.validateForm($form)
			.then(isFormValid => {
				if (!isFormValid)
					return AjaxRequest.GetRejectedPromise<T>();

				var url = $form.attr('action') || '';
				var data = FormDataUtilities.getFormData($form);
				var settings: JQueryAjaxSettings = {
					url: url,
					method: 'POST',
					data: data
				}
				if (isJson)
					settings.dataType = "json";
				if (isFile) {
					settings.processData = false;
					settings.contentType = false;
					settings.dataType = 'json';
					settings.data = <any>new FormData(<HTMLFormElement>$form.get(0));
				}

				return this.sendWithReauthCheck<T>(settings, $form, $form);
			});
	}

	static sendWithReauthCheck<T>(settings: any, reAuthElement: JQuery<HTMLElement>, $form?: JQuery): Promise<T> {
		//TODO: use getReauthToken 
		var isReAuth = reAuthElement.is('[reauth]');
		if (!isReAuth)
			return AjaxRequest.customRequest<T>(settings, undefined, $form);

		var reAuthType = reAuthElement.attr('reauth');
		var authorizeFor = reAuthElement.data('reauth-authorize-for');
		var reAuthPromise: Promise<IReAuthResponse>;

		if (reAuthType === 'modal') {
			reAuthPromise = ReAuthModal.askFor(authorizeFor);
		} else if (reAuthType === 'hidden') {
			reAuthPromise = this.reAuth({ authorizationFor: authorizeFor, isHidden: true });
		} else {
			throw `Unknown reAuth type ${reAuthType}`;
		}

		return reAuthPromise.then(reAuth => {
			if (!reAuth)
				return AjaxRequest.GetRejectedPromise<T>();

			return AjaxRequest.customRequest<T>(settings, reAuth.token, $form);
		});
	}

	static getReauthToken(reAuthElement: JQuery<HTMLElement>): Promise<string | undefined> {
		var isReAuth = reAuthElement.is('[reauth]');
		if (!isReAuth)
			return AjaxRequest.GetResolvedPromise().then(() => undefined);

		var reAuthType = reAuthElement.attr('reauth');
		var authorizeFor = reAuthElement.data('reauth-authorize-for');
		var reAuthPromise: Promise<IReAuthResponse>;

		if (reAuthType === 'modal') {
			reAuthPromise = ReAuthModal.askFor(authorizeFor);
		} else if (reAuthType === 'hidden') {
			reAuthPromise = this.reAuth({ authorizationFor: authorizeFor, isHidden: true });
		} else {
			throw `Unknown reAuth type ${reAuthType}`;
		}

		return reAuthPromise.then(reAuth => {
			if (!reAuth)
				throw "no reauth supplied from server";

			return reAuth.token;
		});
	}

	static postWithReauthCheck<T>($reAuthElement: JQuery<HTMLElement>, data: any) {
		var settings: JQueryAjaxSettings = {
			url: $reAuthElement.data('url'),
			method: 'POST',
			data: data
		}

		return this.sendWithReauthCheck<T>(settings, $reAuthElement);
	}

	static requiresReAuth(reAuthElement: JQuery<HTMLElement>): boolean {
		return reAuthElement.is('[reauth="modal"]');
	}

	static reAuth(data: IReAuthRequest): Promise<IReAuthResponse> {
		var dfd = AjaxRequest.GetDeferred<IReAuthResponse>();
		var promise = dfd.promise();

		$.ajax({
			url: SystemUrls.reauth,
			method: "POST",
			data: <any>data,
			success(response: IAjaxResponse) {
				AjaxRequest.handleSuccess<IReAuthResponse>(response, dfd);
			},
			error(request, textStatus, errorThrown) {
				AjaxRequest.handleError(request, textStatus, errorThrown, dfd);
			}
		});

		return promise;
	}

	private static GetDeferred<T>(): JQuery.Deferred<T, IAjaxError, any> {
		return $.Deferred<T, IAjaxError, any>();
	}

	public static GetResolvedPromise<T>(delay?: number): Promise<T> {
		var dfd = $.Deferred<T, IAjaxError, any>();
		if (delay === undefined) {
			dfd.resolve();
		} else {
			setTimeout(() => dfd.resolve(), delay);
		}

		return dfd.promise();
	}

	public static GetRejectedPromise<T>(): Promise<T> {
		return $.Deferred<T, IAjaxError, any>().reject(new AjaxError(true)).promise();
	}

	public static ToggleAjaxButtonLoading($button: JQuery<HTMLElement>, toggle: boolean) {
		var dataKey = 'toggleAjaxButton';
		if (toggle) {
			$button.data(dataKey, $button.attr('class'));
			$button.attr('class', 'loadingIndicator');
		} else {
			$button.attr('class', $button.data(dataKey));
		}

	}

	private static handleSuccess<T>(response: IAjaxResponse, dfd: JQuery.Deferred<T, IAjaxError, any>): void {
		if (response.success === undefined || Object.keys(response).length > 2)
			Logger.warning("Possible invalid Ajax response", response);

		if (response.success) {
			AjaxRequest.handleFormSuccess();
			dfd.resolve(response.data);
		} else {
			dfd.reject(new AjaxError(false, response.message));
		}
	}

	private static handleError(request: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string,
		dfd: JQuery.Deferred<any, IAjaxError, any>, $form?: JQuery<HTMLElement>): void {
		Logger.debug('Ajax error. Status: ' + textStatus + '. Error Thrown: ' + errorThrown + '. ', request);
		AjaxRequest.handleFail(request, $form).then(() => dfd.reject(new AjaxError(true)));
	}

	private static handleFail(request: JQuery.jqXHR, $form?: JQuery<HTMLElement>): Promise<void> {
		if (request.readyState === 0) {
			Logger.error("network error occured");
			return Dialog.networkError();
		}

		switch (request.status) {
			case 400:
				return this.handleBadRequest(request, $form);
			case 401:
				return Dialog.unauthorized().then(() => {
					window.location.href = SystemUrls.login;
				});
			case 403:
				return Dialog.forbidden();
			case 404:
				return request.responseText.includes('404.13')
					? Dialog.requestTooLarge()
					: Dialog.pageNotFound();
			case 503:
				return Dialog.appOffline();
			default:
				Logger.debug("Unhandled error", request);
				return Dialog.unknownException();
		}
	}

	private static handleBadRequest(request: JQuery.jqXHR, $form?: JQuery<HTMLElement>): Promise<void> {
		var response = JSON.parse(request.responseText);

		this.handleFormError(response, $form);
		return this.GetResolvedPromise();
	}

	private static handleFormSuccess($form?: JQuery<HTMLElement>) {
		if ($form != undefined && $form != null) {
			$form.find('input.input-validation-error,select.input-validation-error').removeClass('input-validation-error');
			$form.find('span.field-validation-error').removeClass('field-validation-error').addClass('field-validation-valid');
		} else {
			//$('body').find('input.input-validation-error,select.input-validation-error').removeClass('input-validation-error');
			//$('body').find('span.field-validation-error').removeClass('field-validation-error').addClass('field-validation-valid');
		}
	}

	private static handleFormError(response: any, $form?: JQuery<HTMLElement>) {
		var fields = Object.keys(response);

		var hasError = false;
		fields.forEach(field => {
			if (response[field].Errors && response[field].Errors.length > 0) {
				var firstError = response[field].Errors[0].ErrorMessage;
				var $element = $form != undefined && $form != null ? $('input[name="' + field + '"], select[name="' + field + '"], textarea[name="' + field + '"]', $form) : $('input[name="' + field + '"], select[name="' + field + '"], textarea[name="' + field + '"]');
				FormUtilities.showValidationErrorForField($element, firstError);
				hasError = true;
			}
		});
		if (hasError) {
			FormUtilities.showTabWhichHasError($form);
		}
	}
}