import "bootstrap/js/dist/modal";
import { IReAuthResponse, IReAuthRequest, IAjaxError } from "../../dogfish.server.interfaces";
import { AjaxRequest } from "../AjaxUtilities";
import { Logger } from "../Logger";

export class ReAuthModal {
    private static readonly enableLogging: boolean;
    private static readonly modalSelector = "#reAuthModal";

    static askFor(authorizationFor: string): Promise<IReAuthResponse> {
        let $modal = this.getModal();
        var dfd = $.Deferred();
        var promise = dfd.promise();

        this.clearModal($modal);
        $modal.modal('show');

        $modal.on('hidden.bs.modal', () => {
            this.clearModal($modal);
            if (dfd.state() === "pending") {
                dfd.resolve(null);
            }
        });

        $modal.off('keypress');
        $modal.on('keypress', e => {
            if (e.which === 13) {
                this.submit(authorizationFor, dfd);
            }
        });

        $('.confirm', $modal).off('click').on('click', () => {
            this.submit(authorizationFor, dfd);
        });

        return promise;
    }

    private static submit(authorizationFor: string, dfd: JQuery.Deferred<any, any, any>) {
        let $modal = this.getModal();
        var reAuthData = this.getData($modal, authorizationFor);
        AjaxRequest.reAuth(reAuthData).then((data: IReAuthResponse) => {
            dfd.resolve(data);
            $modal.modal('hide');
        }).catch((err: IAjaxError) => {
            if (!err.isHandled)
                this.showFailedMessage(err.message);
        });
    }

    private static clearModal($modal: JQuery) {
        $modal.find('input[type="password"]').val('');
    }

    private static getModal(): JQuery {
        return $(this.modalSelector);
    }

    private static getData($modal: JQuery, authorizationFor: string): IReAuthRequest {
        return {
            userId: <string>$('select[name="UserId"]', $modal).val(),
            password: <string>$('input[name="Password"]', $modal).val(),
            authorizationFor: authorizationFor,
            isHidden: false
        };
    }

    private static showFailedMessage(message?: string): void {
        if (!message) {
            message = "Unable to authenticate user for this task.";
        }

        let maxLength = 100;

        var $modal = this.getModal();
        var $errMsg = $modal.find('.errmsg');
        $errMsg.text(message.length > maxLength ? message.substr(0, maxLength - 1) + '...' : message);
        $errMsg.addClass('field-validation-error');
        $errMsg.removeClass('field-validation-valid');
    }

    private static log(message: string, data?: any) {
        if (this.enableLogging)
            data == undefined ? Logger.debug(message) : Logger.debug(message, data);
    }
}