import { Loader } from "@googlemaps/js-api-loader"
import { IGoogleMapSettings } from "../ViewModels";
import { AjaxRequest } from "./AjaxUtilities";
import { Logger } from "./Logger";

$(document.body).ready(() => {
	GoogleMapsUtilities.initGoogleMapsDetails();
});

export class GoogleMapsUtilities {
	private static defaultZoom: number = 13;
	private static isLoaded: boolean = false;
	private static loader: Loader | undefined;	
	private static _GoogleMapSettings: IGoogleMapSettings;

	static markers: google.maps.Marker[] = new Array<google.maps.Marker>();

	public static getLatLonForPostcode(postcode: string): Promise<google.maps.LatLng> {
		var dfd = $.Deferred<google.maps.LatLng, undefined, any>();
		var promise = dfd.promise();
		GoogleMapsUtilities.getLoader()
			.then(() => {				
				var geocoder = new google.maps.Geocoder();
				geocoder.geocode({ address: postcode })
					.then(result => {
						if (result.results.length == 0) {
							console.log('Unable to find lat/lon for the postcode. No results returned.', postcode, result);
							dfd.reject();
						} else {
							var item = result.results[0];
							var lat = item.geometry.location.lat();
							var lon = item.geometry.location.lng();
							console.log('Found lat/lon for postcode', lat, lon, item.geometry.location);
							this.saveClientSiteLatLon(lat, lon);
							dfd.resolve(item.geometry.location);
						}
					})
					.catch(error => {
						console.log('Error loading lat/lon for postcode', postcode, error);
						dfd.reject();
					});				
			})
			.catch(() => dfd.reject());
		return promise;
	}

	public static loadMap(containerId: string, mapOptions: google.maps.MapOptions | undefined = undefined): Promise<google.maps.Map> {
		var dfd = $.Deferred<google.maps.Map, undefined, any>();
		var promise = dfd.promise();

		GoogleMapsUtilities.getLoader()
			.then(() => {
				if (mapOptions == undefined) {
					// If we don't have a default lat/lon we need to get it from the site postcode
					if (this._GoogleMapSettings.defaultLat == undefined || this._GoogleMapSettings.defaultLon == undefined) {
						if (this._GoogleMapSettings.defaultPostcode == undefined || this._GoogleMapSettings.defaultPostcode == '') {
							Logger.debug('No Lat/Lng for location and no postcode to find location.');
							return;
						}
						this.getLatLonForPostcode(this._GoogleMapSettings.defaultPostcode)
							.then(latLon => {
								this.initMap(dfd, containerId, { center: latLon, zoom: this._GoogleMapSettings.defaultZoom || this.defaultZoom });
							})
							.catch(x => dfd.reject());
					} else {
						this.initMap(dfd, containerId, this.getMapOptions(this._GoogleMapSettings.defaultLat,
							this._GoogleMapSettings.defaultLon, this._GoogleMapSettings.defaultZoom || this.defaultZoom));
					}
				} else {
					this.initMap(dfd, containerId, mapOptions);
				}
			})
			.catch(() => dfd.reject(undefined));
		return promise;
	}

	public static setMapCentreToDefault(map: google.maps.Map): void {
		this.setMapCentre(map, new google.maps.LatLng(<number>this._GoogleMapSettings.defaultLat, <number>this._GoogleMapSettings.defaultLon));
	}
	public static setMapCentre(map: google.maps.Map, location: google.maps.LatLng): void {
		map.setCenter(location);
	}

	public static addInfoWindow(content: string | Element, label?: string | null): google.maps.InfoWindow {
		var infoWindow = new google.maps.InfoWindow({
			content: content,
			ariaLabel: label
		});
		return infoWindow;
	}

	public static addMarker(map: google.maps.Map, location: google.maps.LatLng, title?: string | null): google.maps.Marker {
		var marker = new google.maps.Marker({
			position: location,
			title: title,
			label: title,
			map: map
		});
		this.markers.push(marker);
		return marker;
	}

	public static removeMarkers(): void {
		this.markers.forEach(marker => {
			marker.setMap(null);
		})
		this.markers = new Array<google.maps.Marker>()
	}

	private static initMap(dfd: JQuery.Deferred<google.maps.Map, undefined, any>, containerId: string, mapOptions: google.maps.MapOptions): void {
		var map = new google.maps.Map(document.getElementById(containerId) as HTMLElement, mapOptions);
		dfd.resolve(map);
	}

	private static getMapOptions(lat: number, lon: number, zoom: number): google.maps.MapOptions {
		return { center: { lat: lat, lng: lon }, zoom: zoom };
	}




	private static getLoader(): Promise<Loader> {
		var dfd = $.Deferred<Loader, string, any>();
		var promise = dfd.promise();

		if (this.isLoaded) {
			dfd.resolve(<Loader>this.loader);
			return promise;
		}

		var loader = new Loader({
			apiKey: "AIzaSyDYy8ZCSGDEr_K8siF8yVEeWMSsLfbmEhk",
			version: "weekly",
			//...additionalOptions,
		});

		loader.load()
			.then(google => {
				this.loader = loader;
				this.isLoaded = true;
				dfd.resolve(loader);
			})
			.catch(reason => {
				var msg = 'Unable to load Google APIs. Reason: ' + reason;
				console.error(msg, reason);
				dfd.reject(msg);
			});
		return promise;
	}

	static initGoogleMapsDetails(): void {
		var url = $('#googleMapSettingUrl').val();
		if (url != undefined && (<string>url).length) {
			AjaxRequest.getRequest<IGoogleMapSettings>(<string>url, false, true)
				.then(result => {
					this._GoogleMapSettings = result;
				});
		}
	}

	private static saveClientSiteLatLon(lat: number, lon: number): void {
		var url = $('#setClientSiteLocationUrl').val();
		if (url != undefined && (<string>url).length) {
			AjaxRequest.postRequestPromise(<string>url, { lat: lat, lon: lon })
				.then(result => {
					console.log('Saved location for client site', lat, lon);
					this._GoogleMapSettings.defaultLat = lat;
					this._GoogleMapSettings.defaultLon = lon;
				});
		}
	}
}