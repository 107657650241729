import { KeyedCollection, IKeyedCollection } from "./Utilities";

export interface IEventHandler<TIn, TOut> {
    add(handler: { (data: TIn): TOut }): void;
    run(data: TIn): TOut[];
}

export interface IMultiEventHandler<TIn, TOut> {
    add(eventId: string, handler: { (data: TIn): TOut }): void;
    run(eventId: string, data: TIn): TOut[];
}

export class EventHandler<TIn, TOut> implements IEventHandler<TIn, TOut> {
    private handlers: { (data: TIn): TOut; }[] = [];

    add(handler: { (data: TIn): TOut }): void {
        this.handlers.push(handler);
    }

    run(data: TIn): TOut[] {
        var results = new Array<TOut>();
        this.handlers.forEach(h => {
            var result = h(data);
            results.push(result);
        });
        return results;
    }
}

export class MultiEventHandler<TIn, TOut> implements IMultiEventHandler<TIn, TOut> {
    private eventHandlers: IKeyedCollection<IEventHandler<TIn, TOut>> = new KeyedCollection<IEventHandler<TIn, TOut>>();

    add(eventId: string, handler: (data: TIn) => TOut): void {
        if (!this.eventHandlers.containsKey(eventId)) {
            this.eventHandlers.add(eventId, new EventHandler<TIn, TOut>());
        }

        this.eventHandlers.get(eventId).add(handler);
    }

    run(eventId: string, data: TIn): TOut[] {
        if (!this.eventHandlers.containsKey(eventId))
            return [];

        return this.eventHandlers.get(eventId).run(data);
    }
}