import { IDialogSuccess, Dialog, IDialogConfirmSettings } from "./DialogUtilities";
import { AjaxRequest, AjaxUrl } from "./AjaxUtilities";
import { IAjaxError } from "../dogfish.server.interfaces";
import { DataTableHelper } from "./DataTableHelper";

export interface IApplyDtDeleteSettings {
    dt?: DataTableHelper;
    $table: JQuery<HTMLElement>;
    deleteAjaxUrl: AjaxUrl;
    entityName: string;
}

export interface IAjaxConfirmSettings {
    ajaxUrl: AjaxUrl;
    dialogSettings: IDialogConfirmSettings;
    ajaxRequest: () => Promise<void>;
}

export class DogfishUtilities {
    public static ajaxConfirm(settings: IAjaxConfirmSettings) {
        var ajaxUrl = settings.ajaxUrl;
        var isReAuthRequired = ajaxUrl.isReAuthRequired();

        if (!isReAuthRequired) {
            var dialogSettings = settings.dialogSettings;
            if (dialogSettings.onResult === undefined) {
                dialogSettings.onResult = () => settings.ajaxRequest()
                    .then(() => Dialog.defaultSuccessSettings())
                    .catch((err: IAjaxError) => {
                        if (err.isHandled) return Dialog.defaultIgnoreSettings();
                        return Dialog.defaultFailedSettings(err.message);
                    });
            }
            Dialog.confirm(dialogSettings);
        } else {
            settings.ajaxRequest();
        }
    }

    public static refreshPhotoId(): void {
        const originalSrcKey = 'original-src';
        var $img = $('#userPhotoId');
        if (!$img.data(originalSrcKey))
            $img.data(originalSrcKey, $img.attr('src'));

        $img.parent().removeClass('noImage');
        $img.show().attr('src', $img.data(originalSrcKey) + '?' + new Date().getTime());
    }
}