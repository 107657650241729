import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min";

export class Datepicker {
    static readonly defaultDatepickerOptions: DatepickerOptions = {
        format: "dd/mm/yyyy",
        weekStart: 1,
        todayBtn: "linked",
        todayHighlight: true,
        autoclose: true
    };

    static defaultDatepicker($element: JQuery): JQuery {
        return $element.datepicker(this.defaultDatepickerOptions);
    }

    static applyDatepicker($element: JQuery, options: DatepickerOptions): JQuery {
        return $element.datepicker(options);
    }
}