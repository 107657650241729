import SignaturePad from "signature_pad";
import { Logger } from "./Logger";

interface ISignatureInputSettings {
}

export class SignatureInput {
	// Blank 1x1 png
	private static emptySignature = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P4////fwAJ+wP9BUNFygAAAABJRU5ErkJggg==';

	private static editIconSelector = '.editIcon';

	private $container: JQuery<HTMLElement>;
	private $canvasContainer: JQuery<HTMLElement>;
	private canvas: HTMLCanvasElement;
	private $input: JQuery<HTMLElement>;
	private signaturePad: SignaturePad;
	private $deleteBtn: JQuery<HTMLElement>;
	private isLocked: boolean;

	constructor($container: JQuery<HTMLElement>, settings: ISignatureInputSettings) {
		this.$container = $container;
		this.isLocked = $container.attr('disabled') != undefined;
		this.$canvasContainer = $('.canvasContainer', $container)
		this.canvas = <HTMLCanvasElement>$('canvas', $container)[0];
		this.$input = $('input.signatureDataUrl', $container);
		this.$deleteBtn = $('.delete', $container);

		var aspectRatio = parseFloat(<string>this.$canvasContainer.data('aspectratio'));
		var width = <number>this.$container.width();

		this.canvas.setAttribute("width", width.toString());
		this.canvas.setAttribute("height", (width * aspectRatio).toString());
		this.signaturePad = new SignaturePad(this.canvas);

		this.load();
		this.applyEvents();
		if (this.isLocked)
			this.lock();
	}

	private applyEvents() {
		var self = this;

		self.$deleteBtn.click(function () {
			if (self.isLocked)
				return;
			self.reset();
		});

		self.$container.mousedown(function () {
			if (self.isLocked)
				return;
			if (self.$container.is('.active'))
				return;

			self.$container.toggleClass('active', true);
		});
	}

	public load(): void {
		var currentSignature = <string>this.$input.val();
		if (currentSignature != undefined && currentSignature != '') {
			this.signaturePad.fromDataURL(currentSignature, { ratio: 1 });
		}
	}

	public save(): void {
		// Signature isn't initialised don't overwrite the existing signature
		if (this.canvas.width == 0) {
			return;
		}

		var dataUrl = this.signaturePad.toDataURL('image/png');
		this.$input.val(dataUrl);
		console.log(dataUrl);
	}

	public resize(): void {
		if (this.canvas.width > 0) {
			this.save();
		}

		var aspectRatio = parseFloat(<string>this.$canvasContainer.data('aspectratio'));
		var maxHeight = parseFloat(<string>this.$canvasContainer.data('maxheight'));
		var width = <number>this.$container.width();
		var height = isNaN(maxHeight) ? (width * aspectRatio) : Math.min((width * aspectRatio), maxHeight);

		this.canvas.setAttribute("width", width.toString());
		this.canvas.setAttribute("height", height.toString());
		this.signaturePad.off();
		this.signaturePad = new SignaturePad(this.canvas);
		this.load();
		if (this.isLocked) {
			Logger.info("Resizing, but now locking");
			this.lock();
		}
	}

	public unlock(): void {
		this.isLocked = false;
		this.resize();
		$(SignatureInput.editIconSelector, this.$container).show();
	}
	public lockAndClear(): void {
		this.reset();
		this.lock();
	}
	public lock(): void {
		this.isLocked = true;
		this.signaturePad.off();
		$(SignatureInput.editIconSelector, this.$container).hide();
	}

	private reset() {
		this.$input.val("");
		this.$container.toggleClass('active', false);
		this.signaturePad.clear();
	}
}