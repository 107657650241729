import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
import "bootstrap/js/dist/tab";

import { ISiteDetailVM } from "../../ViewModels";
import { AjaxRequest } from "../../Utilities/AjaxUtilities";
import { KeyedCollection } from "../../Utilities/Utilities";
import { ChangeSiteDetailModal } from "./ChangeSiteDetailModal";
import { FormModal } from "../../Utilities/Modal/FormModal";

export class EditClientSiteModal extends FormModal {
    private readonly saveCallback?: Function;
    private readonly loadUrl: string;

    private sitesDictionary = new KeyedCollection<ISiteDetailVM>();
    private $changeSiteDetailModal: ChangeSiteDetailModal;

    constructor($modal: JQuery<HTMLElement>, onSaveCallback?: Function, enableLogging = false) {
        super($modal, enableLogging);
        this.saveCallback = onSaveCallback;
        this.loadUrl = ($modal.find('#ClientSiteDetailsModalRefreshUrl').val()) as string;
        this.$changeSiteDetailModal = new ChangeSiteDetailModal($('div.modal#modalChangeSiteDetail'), () => this.saveCallback);

        this.applyEvents();
    }

    postReload(): void {
        super.postReload();
        this.applyEvents();
    }

    onSuccess(data: any): void {
        if (this.saveCallback != undefined)
            this.saveCallback();
        super.onSuccess(data);
    }

    populate(clientSiteId?: number) {
        var reloadDataUrl = this.loadUrl;
        if (clientSiteId != undefined)
            reloadDataUrl = reloadDataUrl + '/' + (clientSiteId || '');

        return this.show(reloadDataUrl);
    }

    applyEvents() {
        this.log('Applying events');
        var $modal = this.$modal;

        this.initDefaultDatepickers();

        var $autocomplete = $('input.siteNameAutocomplete');
        $autocomplete.autocomplete({
            minLength: 0,
            appendTo: '#' + ($modal.attr('id') as string),
            source: (request: any, response: any) => {
                var term = request.term;
                if (this.sitesDictionary.containsKey(term)) {
                    response(this.sitesDictionary.get(term));
                    return;
                }

                AjaxRequest.getRequest<any>($autocomplete.data('autocompleteurl') + '?searchTerm=' + term)
                    .then(data => {
                        if (data.length)
                            this.sitesDictionary.add(term, data);
                        response(data);
                    });
            },
            select: (_event, ui) => {
                if (ui != undefined) {
                    const siteDetails = ui.item as ISiteDetailVM;
                    this.setSiteDetails(true, siteDetails);
                    return false;
                }

                this.setSiteDetails(false, undefined);
                return false;
            },
            focus(event, ui) {
                if (ui != undefined) {
                    $("#SiteName").val(ui.item.label);
                }
                return false;
            }
        });

        $('.systemModules input:checkbox').change(function () {
            var $landingPage = $('select.landingPage');
            var currentVal = $landingPage.val();
            $landingPage.empty();

            var defaultOption = new Option('Choose...', '', true);
            $landingPage.append(defaultOption);

            var $modules = $('.systemModules input:checked');
            $modules.each((index, element) => {
                var $this = $((element) as any);
                var option = new Option($this.data('displayname'), $this.val() as string);
                $landingPage.append(option);
            });
            $landingPage.val(currentVal !== $(this).val() ? currentVal || '' : '');
        });

        $('.changeSiteDetails').on('click', () => {
            var siteId = $('#SiteId', this.$modal).val() as string || undefined;
            this.$changeSiteDetailModal.populate(siteId);
        });
        this.$changeSiteDetailModal.onHide((): any => {
            var siteDetails = this.$changeSiteDetailModal.getSiteDetails();
            this.setSiteDetails(true, siteDetails);

            if (this.saveCallback != undefined)
                this.saveCallback();
        });
    }

    private setSiteDetails(isDisabled: boolean, details?: ISiteDetailVM): void {
        const boolStr = isDisabled.toString();
        if (details !== undefined) {
            $('#SiteName', this.$form).val(details.siteName);
            $('#SiteId', this.$form).val(details.siteId);
            $('#SiteAddressVM_AddressLine1', this.$form).val(details.addressVM.addressLine1);
            $('#SiteAddressVM_AddressLine2', this.$form).val(details.addressVM.addressLine2);
            $('#SiteAddressVM_AddressLine3', this.$form).val(details.addressVM.addressLine3);
            $('#SiteAddressVM_City', this.$form).val(details.addressVM.city);
            $('#SiteAddressVM_Postcode', this.$form).val(details.addressVM.postcode);
        }
        $('#SiteName', this.$form).attr('readonly', boolStr);
        $('#SiteId', this.$form).attr('readonly', boolStr);
        $('#SiteAddressVM_AddressLine1', this.$form).attr('disabled', boolStr);
        $('#SiteAddressVM_AddressLine2', this.$form).attr('disabled', boolStr);
        $('#SiteAddressVM_AddressLine3', this.$form).attr('disabled', boolStr);
        $('#SiteAddressVM_City', this.$form).attr('disabled', boolStr);
        $('#SiteAddressVM_Postcode', this.$form).attr('disabled', boolStr);
    }
}