import "jquery-ui/ui/widgets/autocomplete";
import "bootstrap-datepicker";

import { ModalBase } from "./ModalBase";
import { AjaxRequest } from "../AjaxUtilities";
import { Dialog } from "../DialogUtilities";
import { IAjaxError } from "../../dogfish.server.interfaces";
import { Timepicker } from "../TimepickerUtilities";
import { Datepicker } from "../../Utilities/DatepickerUtilities";
import { FormUtilities } from "../FormUtilities";
import { EventHandler } from "../EventUtilities";
import { KeyedCollection } from "../Utilities";

export abstract class FormModal extends ModalBase {
	private readonly postAsJson: boolean;
	private readonly postWithFile: boolean;
	protected $saveButton: JQuery<HTMLElement>;
	protected $cancelButton: JQuery<HTMLElement>;

	protected $form: JQuery<HTMLElement>;
	protected isReadonly: boolean = false;

	protected saveEventHandler = new EventHandler<any, void>();
	private tabChangeEventHandler = new EventHandler<ITabChangeEvent, void>();

	constructor($modal: JQuery<HTMLElement>, enableLogging = false, postDataAsJson: boolean = false, postWithFile: boolean = false) {
		super($modal, enableLogging);

		this.$saveButton = $modal.find('.save');
		this.$cancelButton = this.$saveButton.siblings('[data-dismiss]');
		this.$form = $modal.find('form');
		this.postAsJson = postDataAsJson;
		this.postWithFile = postWithFile;

		this._applyEvents();
	}

	onSave(handler: (data: any) => void): void {
		this.saveEventHandler.add(handler);
	}

	protected onSuccess(data: any): void {
		Dialog.success();
		this.hide();
		this.saveEventHandler.run(data);
	}

	protected onError(err: IAjaxError): void {
		if (err.isHandled) return;

		Dialog.failed(err.message);
	}

	protected applyReadonlyStyles() {
		FormUtilities.styleAsReadonly(this.$form);
		this.$saveButton.hide();
		this.$cancelButton.text('Close');
	}

	private _reInit() {
		this.$saveButton = this.$modal.find('.save');
		this.$cancelButton = this.$saveButton.siblings('[data-dismiss]');
		this.$form = this.$modal.find('form');
		this.isReadonly = this.$form.is('[readonly="true"]');

		if (this.isReadonly) {
			this.applyReadonlyStyles();
		}

		if (this.$form.is('[reauth="modal"]') && !this.$saveButton.find('i.fa-key').length) {
			this.$saveButton.prepend('<i class="fa fa-key" aria-hidden="true"></i> ');
		}
	}

	private _applyEvents() {
		var modal = this;
		this.$saveButton.off('click').on('click', (e) => {
			e.preventDefault();
			this.save().catch((err: IAjaxError) => {
				if (err.isHandled) return;

				Dialog.failed(err.message);
			});
		});

		$('a[data-toggle="tab"]', this.$modal).on('shown.bs.tab', function (e) {
			var $tab = $(e.target);
			var $tabContent = $($tab.attr("href"));
			modal.tabChangeEventHandler.run({ $tab, $tabContent });
		});
	}

	protected postReload(): void {
		this._reInit();
		this._applyEvents();
	}

	protected toggleLoading(isLoading: boolean): void {
		super.toggleLoading(isLoading);
		this.$saveButton.prop('disabled', isLoading);
	}

	protected save(): Promise<any> {
		this.toggleSaving(true);
		return AjaxRequest.postForm(this.$form, this.postAsJson, this.postWithFile)
			.then((data) => {
				this.toggleSaving(false);
				this.onSuccess(data);
				return data;
			})
			.catch((err: IAjaxError) => {
				this.toggleSaving(false);
				this.onError(err);
				throw err;
			});
	}

	protected initDefaultDatepickers() {
		Datepicker.defaultDatepicker($('.datepicker', this.$form));
	}

	protected initDefaultTimepickers() {
		Timepicker.initDefaultTimepicker($('.timepicker', this.$form));
	}

	protected toggleSaving(isSaving: boolean) {
		this.$cancelButton.prop('disabled', isSaving);
		// Also disable save button to stop people doubleclicking and resubmitting
		this.$saveButton.prop('disabled', isSaving);
		AjaxRequest.ToggleAjaxButtonLoading(this.$saveButton, isSaving);
	}

	protected initDefaultAutocomplete<T>($autocomplete: JQuery, onResult?: (data: T) => void, cache = new KeyedCollection<T>()): void {
		$autocomplete.autocomplete({
			minLength: 0,
			appendTo: '#' + <string>(this.$modal.attr('id')),
			source: (request: any, response: any) => {
				const term = request.term;
				if (cache.containsKey(term)) {
					response(cache.get(term));
					return;
				}

				AjaxRequest.getRequest<any>($autocomplete.data('autocompleteurl') + '?searchTerm=' + term, false)
					.then(data => {
						if (data.length)
							cache.add(term, data);
						response(data);
					});
			},
			select: (_event, ui) => {
				if (onResult) {
					onResult(ui.item);
				} else {
					$autocomplete.val(ui.item.value);
				}
				return false;
			}
		});
	}


	onTabChange(handler: (tabChangeEventData: ITabChangeEvent) => void): void {
		this.tabChangeEventHandler.add(handler);
	}
}


export interface ITabChangeEvent {
	$tab: JQuery<HTMLElement>;
	$tabContent: JQuery<HTMLElement>;
}
