import "../../wwwroot/js/croppie/croppie.min.js";
import { WebcamModal } from "./Modal/WebcamModal";

export interface IPhotoEditorSettings {
	noCrop?: boolean;
	noCam?: boolean;
	[x: string]: any; // allow any other property
}

export class PhotoEditor {
    private readonly originalSrcKey: string = "original-src";
    private readonly hasCroppieKey: string = "has-croppie";

    private readonly $photoIdInput: JQuery<HTMLElement>;
    private readonly $imgContainer: JQuery<HTMLElement>;
    private readonly $img: JQuery<HTMLElement>;
    private readonly $deleteBtn: JQuery<HTMLElement>;
    private readonly $webcamBtn: JQuery<HTMLElement>;
    private readonly $deleteInput: JQuery<HTMLElement>;
    private readonly $fileInput: JQuery<HTMLElement>;
	private readonly settings: IPhotoEditorSettings;

	constructor($container: JQuery<HTMLElement>, settings: IPhotoEditorSettings) {
        this.$imgContainer = $('.imgContainer', $container);
        this.$img = $('img.photo', $container);
        this.$fileInput = $('.photoFile', $container);
        this.$photoIdInput = $('input.inputPhoto', $container);
        this.$deleteBtn = $('button.delete', $container);
        this.$webcamBtn = $('button.webcam', $container);
        this.$deleteInput = $('input.inputDeletePhoto', $container);
		this.settings = settings;

		if (settings.noCam) {
			this.$webcamBtn.hide();
		}

        if (!this.$imgContainer.length) {
            console.warn("PhotoEditor cannot be initialised as required HTML elements do not exist");
            return;
        }

        this.applyEvents();
    }

	private applyEvents() {
        var $img = this.$img;

		if ($img.attr('src') != undefined)
			$img.data(this.originalSrcKey, $img.attr('src'));
		else
			$img.data(this.originalSrcKey, '');

        $img.on('error', () => {
            $img.hide();
            $img.siblings('i').show();
        });

        this.$imgContainer.click(() => {
            if ($img.data(this.hasCroppieKey)) return;
            this.$fileInput.trigger('click');
        });

        this.$fileInput.change(e => {
            this.handleFileInput(<any>e.target);
        });

        this.$deleteBtn.click(() => this.handleDelete());
        this.$webcamBtn.click(() => this.handleWebcam());

        this.refreshDeleteBtn();
    }

    public saveEdit(): Promise<void> {
        if (this.settings.noCrop && this.isChanged()) {
            this.$photoIdInput.val(<string>this.$img.attr('src'));
            return $.Deferred().resolve().promise();
        }

        if (!this.$img.data(this.hasCroppieKey)) {
            this.$photoIdInput.val('');
            return $.Deferred().resolve().promise();
        }

        return this.$img.croppie('result', { type: 'base64' }).then(result => {
            this.$photoIdInput.val(result);
        });
    }

    private refreshDeleteBtn() {
        var $imgContainer = this.$imgContainer;
        var $img = this.$img;
        var $deleteBtn = this.$deleteBtn;
        var $fileInput = this.$fileInput;
        var changed = this.isChanged();
        var empty = this.isEmpty();
        var settings = this.settings;
        var $imgI = $img.siblings('i');

        $('i', $deleteBtn).toggleClass('fa-trash', !changed);
        $('i', $deleteBtn).toggleClass('fa-times', changed);

        if (changed && !empty) {
            if (!this.settings.noCrop) {
                $img.croppie(settings);
                $img.data(this.hasCroppieKey, true);
                $img.hide();
            }

            $imgI.hide();
        } else {
            if (!this.settings.noCrop) {
                $img.croppie('destroy');
                $img.data(this.hasCroppieKey, false);
            }

            $imgContainer.append($img);

            $img.toggle(!empty);
            $imgI.toggle(empty);
        }
    }

    private isChanged(): boolean {
        var $img = this.$img;
        return $img.attr('src') !== $img.data(this.originalSrcKey);
    }

    private isEmpty(): boolean {
        return !this.$img.attr('src');
    }

    private handleDelete() {
        var $img = this.$img;
        var $fileInput = this.$fileInput;
        var $deleteInput = this.$deleteInput;

        $fileInput.val('');
        if (this.isChanged()) {
            $img.attr('src', $img.data(this.originalSrcKey));
            $deleteInput.prop('checked', false);
        } else {
            $img.attr('src', '');
            $deleteInput.prop('checked', true);
            $fileInput.val('');
        }

        this.refreshDeleteBtn();
    }

    private handleFileInput(input: HTMLInputElement) {
        if (!input.files || !input.files[0]) return;
        this.$deleteInput.prop('checked', false);

        var reader = new FileReader();
        reader.onload = e => {
            this.$img.show().attr('src', (<any>e.target).result);
            this.refreshDeleteBtn();
        }

        reader.readAsDataURL(input.files[0]);
    }

    private handleWebcam() {
        WebcamModal.capture().then(imageUrl => {
            if (imageUrl === null) return;

            this.$deleteInput.prop('checked', false);
            this.$img.attr('src', this.$img.data(this.originalSrcKey));
            this.refreshDeleteBtn();

            this.$img.show().attr('src', imageUrl);
            this.refreshDeleteBtn();
        });
    }
}