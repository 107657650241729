import $ from "jquery";

// can't get it to work
//$.fn.extend({
//    flash: function () {
//        return this.each(function () {
//            this.addClass('flash');
//            setTimeout(() => this.removeClass('flash'), 1000);
//            return this;
//        });
//    }
//});

export function flash($element: JQuery): JQuery {
    $element.addClass('flash');
    setTimeout(() => $element.removeClass('flash'), 1000);
    return $element;
}