import $ from "jquery";
import switchery from "switchery";

$(document.body).ready(() => {
    showTabFromUrl(window.location.href);

    window.addEventListener('popstate', e => {
        showTabFromUrl(window.location.href);
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', e => {
        var target: any = e.target;
        window.location.hash = target.hash;
    });

    $('.nav-tabs a').on('shown.bs.tab', e => {
        var $tabContainer = $($(<any>e.target).attr('href'));
        initSwitches($tabContainer);
    });
});

function showTabFromUrl(url: string) {
    if (!url.match('#')) return;

    var $target = $('.nav-tabs a[href="#' + url.split('#')[1] + '"]');
    $($target.parents('.tab-pane').get().reverse()).each(function () {
        var $parentPane = $(<any>this);
        var $parentTarget = $('.nav-tabs a[href="#' + <any>$parentPane.attr('id') + '"]');
        $parentTarget.trigger('click');
    });
    $target.trigger('click');
    var $tabContainer = $($target.attr('href'));
    initSwitches($tabContainer);
}

function initSwitches($container: JQuery) {
    $container.find('.js-switch:visible').get().forEach((elem: any) => {
        if ($(elem).is('[data-switchery="true"]'))
            return;
        var s = new switchery(elem);
        $(elem).attr('data-switchery', 'true');
    });
}