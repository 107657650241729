export class AutocompleteUtilities {
	private static previewDataKey: string = 'original-val';
	private static previewClassName: string = 'preview';

	static previewInput($input: JQuery, data: any): void {
		if ($input.data(AutocompleteUtilities.previewDataKey) === undefined) {
			$input.data(AutocompleteUtilities.previewDataKey, $input.val());
		}

		$input.val(data).addClass(AutocompleteUtilities.previewClassName);
	}

	static resetPreview($input: JQuery, resetVal: boolean = false): JQuery {
		$input.removeClass(AutocompleteUtilities.previewClassName);
		var original = $input.data(AutocompleteUtilities.previewDataKey);
		if (resetVal && original !== undefined) {
			$input.val(original);
		}

		return $input;
	}
}