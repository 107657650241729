import "../../wwwroot/js/bootstrap-timepicker/bootstrap-timepicker.min.js";

export class Timepicker {
    static initDefaultTimepicker($selector: JQuery<HTMLElement>) {
        $selector.each((_index, element) => {
            var $this = $((element) as any);
            $this.timepicker({
                showMeridian: false,
                defaultTime: 'value?',
                icons: {
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down'
                }
            });
            $this.siblings('.input-group-append').on('click', () => {
                $this.timepicker('showWidget');
            });
        });

        $selector.on('blur', function (e) {
            if ($(<any>e.relatedTarget).parents(".bootstrap-timepicker-widget").length)
                return;

            $(this).timepicker('hideWidget');
        });
    }
    static initTimepicker($selector: JQuery<HTMLElement>, options: TimepickerOptions) {
        $selector.each((_index, element) => {
            var $this = $((element) as any);
            $this.timepicker(options);
            $this.siblings('.input-group-append').on('click', () => {
                $this.timepicker('showWidget');
            });
        });
    }
}