import "bootstrap/js/dist/modal";

export class WebcamModal {
    private static readonly enableLogging: boolean;
    private static readonly modalSelector = "#webcamModal";
    private static readonly videoSelector = "video.webcamFeed";
    private static readonly canvasSelector = "canvas.captureCanvas";
    private static readonly captureBtnSelector = ".captureBtn";
    private static readonly captureCancelBtnSelector = ".captureCancelBtn";

    static capture(): Promise<string | null> {
        let $modal = $(this.modalSelector);
        var dfd = $.Deferred();

        this.resetModal();
        $modal.modal('show');

        $modal.on('hidden.bs.modal', () => {
            if (dfd.state() === "pending") {
                dfd.resolve(null);
            }
            this.onClose();
        });

        this.applyEvents();

        //add constraints object
        var constraints = {
            video: true
        };

        //call getUserMedia, then the magic
        WebcamModal.getUserMedia(constraints).then(mediaStream => {
            var $video = $(this.videoSelector, $modal);
            if (!$video.length) {
                console.warn("Failed to start webcam feed. Unable to find video element");
                this.handleVideoError();
                return;
            }

            var video: HTMLVideoElement = <any>$video.get(0);
            video.srcObject = mediaStream;
            video.play();

            $('.confirm', $modal).off('click').on('click', e => {
                dfd.resolve(this.getCapturedImageUrl());
                $modal.modal('hide');
            });

        }).catch(err => {
            console.log("Webcam error: " + err.message, err);
            this.handleVideoError(err);
        });

        $('.cancel', $modal).off('click').on('click', e => {
            this.onClose();
        });

        return dfd.promise();
    }

    private static handleVideoError(err?: any) {
        $(this.modalSelector).toggleClass('noCamera', true).find('.confirm').prop('disabled', true);
        var $noCamera = $('.noCamera', $(this.modalSelector));
        var message = err && err.browserNotSupported
            ? $noCamera.data('browser-message')
            : $noCamera.data('default-message');

        $('.message', $noCamera).text(message);
    }

    private static resetModal(): void {
        let $modal = $(this.modalSelector);
        let $canvas = $(this.canvasSelector, $modal);
        let $video = $(this.videoSelector, $modal);
        let $captureBtn = $(this.captureBtnSelector, $modal);
        let $captureCancelBtn = $(this.captureCancelBtnSelector, $modal);

        $(this.modalSelector).toggleClass('noCamera', false).find('.confirm').prop('disabled', false);

        $canvas.hide();
        $captureCancelBtn.hide();
        $captureBtn.show();
        $video.show();
    }

    private static onClose(): void {
        let $modal = $(this.modalSelector);
        let video: HTMLVideoElement = <any>$(this.videoSelector, $modal)[0];
        var stream: MediaStream = <any>video.srcObject;
        try {

            var tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
        } catch (err) {
            // do nothing
        }

        video.srcObject = null;
    }

    private static applyEvents() {
        let $modal = $(this.modalSelector);
        let $captureBtn = $('.captureBtn', $modal);
        let $captureCancelBtn = $('.captureCancelBtn', $modal);
        let $canvas = $(this.canvasSelector, $modal);
        let $video = $(this.videoSelector, $modal);

        $captureBtn.off('click').on('click', e => {
            var canvas: HTMLCanvasElement = <any>$canvas.get(0);
            var ctx = canvas.getContext('2d');
            if (!ctx) {
                console.warn('canvas context is null');
                return;
            }

            ctx.drawImage(<any>$video.get(0), 0, 0);
            $captureBtn.hide();
            $video.hide();
            $canvas.show();
            $captureCancelBtn.show();
        });

        $captureCancelBtn.off('click').on('click', e => {
            this.resetModal();
        });
    }

    private static getCapturedImageUrl(): string {
        let $modal = $(this.modalSelector);
        let $canvas = $(this.canvasSelector, $modal);
        var canvas: HTMLCanvasElement = <any>$canvas.get(0);

        return canvas.toDataURL();
    }

    private static getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream> {
        var getUserMedia = (constraints: MediaStreamConstraints): Promise<MediaStream> => {

            // First get ahold of the legacy getUserMedia, if present
            var getUserMedia = (<any>navigator).webkitGetUserMedia || (<any>navigator).mozGetUserMedia;

            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
                return Promise.reject({ message: 'getUserMedia is not supported by the browser', browserNotSupported: true });
            }

            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
            return new Promise((resolve, reject) => {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });
        };

        if (navigator.mediaDevices === undefined) {
            return getUserMedia(constraints);
        }

        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = getUserMedia;
        }

        return navigator.mediaDevices.getUserMedia(constraints);
    }
}