export class FormDataUtilities {

	static getFormData($form: JQuery<HTMLElement>) {
		var unindexedArray = $form.serialize();
		return unindexedArray;
	}
}

export interface IKeyedCollection<T> {
	add(key: string, value: T): void;
	containsKey(key: string): boolean;
	count(): number;
	get(key: string): T;
	keys(): string[];
	remove(key: string): T;
	values(): T[];
}

export class KeyedCollection<T> implements IKeyedCollection<T> {
	private items: { [index: string]: T } = {};

	private _count: number = 0;

	containsKey(key: string): boolean {
		return this.items.hasOwnProperty(key);
	}

	count(): number {
		return this._count;
	}

	add(key: string, value: T): void {
		if (!this.items.hasOwnProperty(key))
			this._count++;

		this.items[key] = value;
	}

	remove(key: string): T {
		var val = this.items[key];
		delete this.items[key];
		this._count--;
		return val;
	}

	get(key: string): T {
		return this.items[key];
	}

	keys(): string[] {
		var keySet: string[] = [];

		for (var prop in this.items) {
			if (this.items.hasOwnProperty(prop)) {
				keySet.push(prop);
			}
		}

		return keySet;
	}

	values(): T[] {
		var values: T[] = [];

		for (var prop in this.items) {
			if (this.items.hasOwnProperty(prop)) {
				values.push(this.items[prop]);
			}
		}

		return values;
	}
}


export class BooleanUtilities {
	static getBool(value: string | null | undefined, defaultReturn: boolean = false): boolean {
		if (value == undefined || value == null)
			return defaultReturn;
		if (typeof value === 'boolean')
			return <boolean>value;

		var lowerVal = value.toLowerCase();
		if (lowerVal === 'false')
			return false;
		if (lowerVal === 'true')
			return true;
		return defaultReturn;
	}
}
export class DateUtilities {
	static getYearMonthDateFormat(date: Date): string {
		let month = '' + (date.getMonth() + 1);
		let day = '' + date.getDate();
		const year = date.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}
	static getDateMonthYearFormat(date: Date): string {
		let month = '' + (date.getMonth() + 1);
		let day = '' + date.getDate();
		const year = date.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [day, month, year].join('/');
	}

	static getMonday(date: Date) {
		var day = date.getDay();
		var diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(date.setDate(diff));
	}
	static getFirstDayOfMonth(date: Date) {
		return new Date(date.getFullYear(), date.getMonth(), 1);

	}
	static getLastDayOfMonth(date: Date) {
		return new Date(date.getFullYear(), date.getMonth() + 1, 0);
	}

	static getFirstDayOfYear(date: Date) {
		// Month is 0 based
		return new Date(date.getFullYear(), 0, 1);
	}
	static getLastDayOfYear(date: Date) {
		// Month is 0 based
		return new Date(date.getFullYear() + 1, 0, 0);
	}

	static addDays(date: Date, days: number): Date {
		date.setDate(date.getDate() + days);
		return date;
	}
	static addMonths(date: Date, months: number): Date {
		date.setMonth(date.getMonth() + months);
		return date;
	}
	static addYears(date: Date, years: number): Date {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}

	static getDateFromDayMonthYear(dateString: string): Date {
		var dateParts = dateString.split("/");
		// month is 0-based, that's why we need dataParts[1] - 1
		var dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
		return dateObject;
	}
}