import { PhotoEditor } from "../../Utilities/PhotoEditor";

export class PhotoIdEditor extends PhotoEditor {
    constructor($form: JQuery<HTMLElement>) {
        super($form, {
            showZoomer: false,
            viewport: {
                width: 100,
                height: 100,
                type: 'circle'
            }
        });
    }
}