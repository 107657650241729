import { Subscriptions } from "../../Utilities/SubscriptionsUtilities";
import {
    ActivityLogStatusChangedEventVM, ScheduledTaskStatusChangedEventVM, TaskStatusType, VisitorStatusChangedEventVM,
    AssetStatusChangedEventVM
} from "../../ViewModels";
import { DogfishNotification } from "../../Utilities/NotificationUtilities";

export class ClientSiteWidgets {
    static initalise(): void {
        this.enableActivityLogWidgets();
        this.enableTaskWidgets();
        this.enablePatrolsWidgets();
        this.enableVisitorsWidget();
        this.enableAssetsWidget();
    }

    private static enableActivityLogWidgets(): void {
        Subscriptions.subscribeToEvent<ActivityLogStatusChangedEventVM>("ActivityLogStatusChangedEventVM", e => {
            var $activityLogPending = $('#activityWidget #activityLogPending');
            var $activityLogComplete = $('#activityWidget #activityLogComplete');
            var newPending = Math.max(0, parseInt($activityLogPending.text()) + e.pendingChange);
            var newComplete = Math.max(0, parseInt($activityLogComplete.text()) + e.completedChange);

            $activityLogPending.text(ClientSiteWidgets.toNumberString(newPending, 6));
            $activityLogComplete.text(ClientSiteWidgets.toNumberString(newComplete, 6));
        });
    }

    private static enableTaskWidgets(): void {
        Subscriptions.subscribeToEvent<ScheduledTaskStatusChangedEventVM>("ScheduledTaskStatusChangedEventVM", e => {
            var $taskPending = $('#taskWidget #taskPending');
            var $taskCompleted = $('#taskWidget #taskCompleted');
            var newPending = Math.max(0, parseInt($taskPending.text()) + e.pendingChange);
            var newCompleted = Math.max(0, parseInt($taskCompleted.text()) + e.completedChange);

            $taskPending.text(ClientSiteWidgets.toNumberString(newPending, 2));
            $taskCompleted.text(ClientSiteWidgets.toNumberString(newCompleted, 2));

            if (e.newStatus === TaskStatusType.Overdue) {
                DogfishNotification.windowMessage(`Task '${e.subject}' is overdue`);
            }
        });
    }

    private static enablePatrolsWidgets(): void {
        //Subscriptions.subscribeToEvent(PatrolStatusChangedEventVM, e => {
        //var $taskPending = $('#taskWidget #taskPending');
        //var $taskCompleted = $('#taskWidget #taskCompleted');
        //var newPending = Math.max(0, parseInt($taskPending.text()) + e.pendingChange);
        //var newCompleted = Math.max(0, parseInt($taskCompleted.text()) + e.completedChange);

        //$taskPending.text(ClientSiteWidgets.toNumberString(newPending, 2));
        //$taskCompleted.text(ClientSiteWidgets.toNumberString(newCompleted, 2));
        //});
    }

    private static enableVisitorsWidget(): void {
        Subscriptions.subscribeToEvent<VisitorStatusChangedEventVM>("VisitorStatusChangedEventVM", e => {
            var $preBooked = $('#visitorsWidget #visitorsPreBooked');
            var $onSite = $('#visitorsWidget #visitorsOnSite');
            var $offSite = $('#visitorsWidget #visitorsOffSite');

            var newPreBooked = Math.max(0, parseInt($preBooked.text()) + e.preBookedChange);
            var newOnSite = Math.max(0, parseInt($onSite.text()) + e.onSiteChange);
            var newOffSite = Math.max(0, parseInt($offSite.text()) + e.offSiteChange);

            $preBooked.text(ClientSiteWidgets.toNumberString(newPreBooked, 2));
            $onSite.text(ClientSiteWidgets.toNumberString(newOnSite, 2));
            $offSite.text(ClientSiteWidgets.toNumberString(newOffSite, 2));
        });
    }

    private static enableAssetsWidget(): void {
        Subscriptions.subscribeToEvent<AssetStatusChangedEventVM>("AssetStatusChangedEventVM", e => {
            var $issued = $('#assetsWidget #assetsIssued');
            var $overdue = $('#assetsWidget #assetsOverdue');

            var newIssued = Math.max(0, parseInt($issued.text()) + e.issuedChange);
            var newOverdue = Math.max(0, parseInt($overdue.text()) + e.overdueChange);

            $issued.text(ClientSiteWidgets.toNumberString(newIssued, 2));
            $overdue.text(ClientSiteWidgets.toNumberString(newOverdue, 2));
        });
    }

    private static toNumberString(value: number, minimumDigits: number): string {
        return value.toLocaleString('en-gb', { minimumIntegerDigits: minimumDigits, useGrouping: false });
    }
}