import { AjaxRequest } from "../../Utilities/AjaxUtilities";
import { FlotUtilities, IPieChartDataVM } from "../../Utilities/FlotUtilities";
import { MorrisUtilities, IStackedChartDataVM } from "../../Utilities/MorrisUtilities";
import { BreakdownReportSearch } from "./Dashboard.ActivityLogReport.BreakdownSearch";

export class ActivityLogReportGraphs {
    private breakdownSearch: BreakdownReportSearch;

    private static currentYearValues = 'currentYear';
    private static compareYearValues = 'compareYear';

    private static periodDisplay = 'Period';
    private static compareToPeriodDisplay = 'Compare to Period';


    constructor($container: JQuery, isClientAdmin: boolean) {
        var $breakdownContainer = $('.comparisonChartContainer', $container);
        this.breakdownSearch = new BreakdownReportSearch($breakdownContainer, isClientAdmin);
        this.breakdownSearch.applyEvents();
        this.addBreakdownReport($breakdownContainer);
        this.initaliseDonutCharts($container);

        $container.on(BreakdownReportSearch.breakdownReportSubmitEvent, e => {
            $('#activityLogBreakdown').remove();
            this.addBreakdownReport($breakdownContainer);
        });
    }

    initaliseDonutCharts($container: JQuery) {
        AjaxRequest.getRequest<IPieChartDataVM[]>($container.data('getsummaryurl'), true, false)
            .then(data => {
                if (data.length === 0) {
                    this.removeSummaryRow();
                    return;
                }

                data.forEach((value, index) => {
                    var currentChart = index + 1;
                    if (currentChart % 4 === 1 && currentChart !== 1) {
                        this.createNewSummaryRow(currentChart);
                    }

                    var $summaryContainer: JQuery = $('.summary' + currentChart);
                    var id = 'activityLog_' + value.title;
                    var $h5 = $('<h5></h5>')
                        .text(value.title)
                        .appendTo($summaryContainer);

                    if (value.subtitle !== undefined && value.subtitle !== null && value.subtitle !== "") {
                        const $subTitle = $('<span class="smaller"></span>')
                            .text(' - ' + value.subtitle)
                            .appendTo($h5);
                    }

                    var headerHeight = ($h5.height() || 0);
                    var $div = $('<div></div>')
                        .attr('id', id)
                        .addClass('pieChart')
                        .css('width', '100%')
                        .css('height', headerHeight > 60 ? '65%' : headerHeight > 30 ? '75%' : '85%')
                        .appendTo($summaryContainer);

                    var data = value.values.map((value, index, array) => {
                        return { label: value.label, value: value.count }
                    });
                    MorrisUtilities.createDonutChart($div, data, undefined, true);
                });
            });
    }

    addBreakdownReport($container: JQuery) {
        var $div = $('<div></div>')
            .attr('id', 'activityLogBreakdown')
            .addClass('activityLogBreakdown')
            .appendTo($container);

        var url = $container.data('getbreakdownurl') + this.breakdownSearch.getQueryString();
        AjaxRequest.getRequest<IStackedChartDataVM>(url, true, false)
            .then(data => {
                var isCustomSearch = this.breakdownSearch.isCustomSearch;
                var currentYear = isCustomSearch ? ActivityLogReportGraphs.periodDisplay : this.breakdownSearch.currentYear();
                var compareYear = isCustomSearch ? ActivityLogReportGraphs.compareToPeriodDisplay : this.breakdownSearch.comparisonYear();
                var periodTitle = this.breakdownSearch.getPeriodTitle();
                var comparePeriodTitle = this.breakdownSearch.getCompareTitle();
                var chartData = this.getStackedData(data);

                if (chartData.length) {
                    var breakdownChart = MorrisUtilities.createStackedChart($div, chartData, "x", [ActivityLogReportGraphs.currentYearValues, ActivityLogReportGraphs.compareYearValues], [periodTitle, comparePeriodTitle]);
                    //var breakdownChart = MorrisUtilities.createStackedChart($div, chartData, "x", [ActivityLogReportGraphs.currentYearValues, ActivityLogReportGraphs.compareYearValues], [currentYear, compareYear]);
                    this.breakdownSearch.updateChart(breakdownChart);
                } else {
                    $div.text('No data to display');
                    $div.addClass('noData');
                }
            });
    }

    private createNewSummaryRow(currentChart: number): void {
        var $newRow = $('#activityLog #activityLog-reportContainer > div.row:nth-child(2)').clone();
        $('div.col-3', $newRow).each((index, element) => {
            var $elem = $(element);
            var $container = $('div.activityLogSummary', $elem);
            $container.removeClass(new Array<string>('summary1', 'summary2', 'summary3', 'summary4'));
            $container.addClass('summary' + (currentChart + index));
            $container.html('');
        });
        $($newRow).appendTo('#activityLog-reportContainer');
    }
    private removeSummaryRow(): void {
        $('#activityLog #activityLog-reportContainer > div.row:nth-child(2)').remove();
    }

    private getStackedData(data: IStackedChartDataVM): any {
        var chartData = new Array<any>();
        data.values.forEach((value) => {
            if (value.counts.length > 1) {
                chartData.push({ x: value.shortLabel, fullLabel: value.label, currentYear: value.counts[0], compareYear: value.counts[1] });
            } else {
                chartData.push({ x: value.shortLabel, fullLabel: value.label, currentYear: value.counts[0], compareYear: 0 });
            }
        });
        return chartData;
    }
}